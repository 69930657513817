"use client"

import styled from "@emotion/styled"
import Link from "next/link"
import { ImageContainer, NewText } from "ui"
import { sharedColors } from "ui/theme/colors"
import { articlesClick } from "utils"

const StyledLink = styled(Link)`
  text-decoration: none;
`

const StyledContainer = styled.div`
  border-radius: 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  cursor: pointer;
  padding: 16px;
  & h2,
  & h1 {
    display: -webkit-box; /* For iOS and Safari */
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  ${(props) => props.theme.breakpoints.down("md")} {
    box-shadow: 0px 4px 12px 0px #00000029;
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    padding: 0px;
  }
`

const StyledBottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

type Props = {
  logo: string
  title: string
  subTitle: string
  date: string
  imageDesktop: string
  imageMobile: string
  content: string
  link: string
  associationText: any
}

export function ArticleItem({
  title,
  subTitle,
  date,
  imageDesktop,
  imageMobile,
  link,
  associationText,
}: Props) {
  // const isDesktop = useIsDesktop()
  const ndate = new Date(date)
  const yyyy = ndate.getFullYear()
  const mm = ndate.getMonth() + 1
  const dd = ndate.getDate()

  const formattedDate = dd + "/" + mm + "/" + yyyy

  // const onShare = async () => {
  //   const shareData = {
  //     title: title || "",
  //     url: link,
  //     // url:
  //     //   window.location.href +
  //     //   "/" +
  //     //   `${locale === "en" ? "en" : ""}` +
  //     //   job.orderId,
  //   }
  //   if (navigator.share && navigator.canShare(shareData)) {
  //     try {
  //       await navigator.share(shareData)
  //     } catch (err) {
  //       console.error(err)
  //     }
  //   } else {
  //     navigator.clipboard.writeText(shareData.url)
  //   }
  // }

  return (
    <StyledLink href={link}>
      <StyledContainer onClick={() => articlesClick(title)}>
        <ImageContainer
          desktopUrl={imageDesktop}
          mobileUrl={imageMobile}
          height={244}
          mobileHeight={244}
          borderRadius={8}
        />

        <NewText
          desktopFontSize={20}
          desktopFontWeight="600"
          desktopLineHeight="28.8px"
          mobileFontSize={18}
          mobileFontWeight="600"
          mobileLineHeight={"25.92px"}
          component="h1"
          color={"black"}
          marginTop={"8px"}
          height="58px"
        >
          {title}
        </NewText>
        <NewText
          desktopFontSize={18}
          desktopFontWeight="400"
          desktopLineHeight="26px"
          mobileFontSize={16}
          mobileFontWeight="400"
          mobileLineHeight={"23.04px"}
          component="h2"
          color={"black"}
          marginTop={"8px"}
          height="51px"
        >
          {subTitle}
        </NewText>

        <StyledBottom>
          {associationText ? (
            <NewText
              desktopFontSize={16}
              desktopFontWeight="400"
              desktopLineHeight="24px"
              mobileFontSize={14}
              mobileFontWeight="400"
              mobileLineHeight={"24px"}
              component="h1"
              color={sharedColors.gray}
              marginTop={"8px"}
            >
              {associationText}
            </NewText>
          ) : (
            <span />
          )}

          <NewText
            desktopFontSize={16}
            desktopFontWeight="400"
            desktopLineHeight="24px"
            mobileFontSize={14}
            mobileFontWeight="400"
            mobileLineHeight={"24px"}
            component="h1"
            color={sharedColors.gray}
            marginTop={"8px"}
          >
            {formattedDate}
          </NewText>
        </StyledBottom>
      </StyledContainer>
    </StyledLink>
  )
}

export default ArticleItem
