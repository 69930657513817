"use client"

import { BlockRenderer } from "ui"
import BottomTextAndImage from "../Careers/BottomTextAndImage"
import TopContent from "./TopContent"

type Props = {
  data: any
  job: any
  messages?: any
}

const components = {
  bottomTextAndImage: BottomTextAndImage,
}

function Career({ data, job, messages }: Props) {
  const hasBanner = data?.page?.properties?.some(
    (block: any) => block.blockType === "topBanner" || block.blockType === "HeaderWithHeroAndText",
  )
  return (
    <>
      <TopContent job={job} messages={messages} />
      {data?.page?.properties?.map((block: any, index: number) => {
        let additionalProps = {}
        if (block.blockType === "contentText") {
          additionalProps = {
            hasBanner,
          }
        }
        return (
          <BlockRenderer
            key={index}
            blockType={block.propertyName}
            blockData={block}
            components={components}
            props={additionalProps}
          ></BlockRenderer>
        )
      })}
    </>
  )
}

export default Career
