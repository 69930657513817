import styled from "@emotion/styled"
import { useInView } from "react-intersection-observer"
import {
  Container,
  NewText,
  TransitionUp,
  transitionDelayTime,
  useInViewConfig,
} from "ui"
import SectionTitle from "ui/SectionTitle"
import { sharedColors } from "ui/theme/colors"
import { extractProperty } from "utils/extractProperty"
import ReportsWrapper from "./ReportsWrapper"

const StyledReportsDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-top: 24px;
  ${(props) => props.theme.breakpoints.up("md")} {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 45px;
    margin-top: 40px;
  }
`

type Props = {
  data: any
}

function Reports({ data }: Props) {
  const title = extractProperty(data, "title")
  const textLine = extractProperty(data, "textLine")
  const reportsBlock = extractProperty(data, "reportsBlock")
  const { ref, inView } = useInView(useInViewConfig)
  return (
    <Container containerRef={ref}>
      <SectionTitle
        title={title}
        accentColor={sharedColors.propBlue}
        accent={false}
      />
      <NewText
        desktopFontSize={24}
        desktopFontWeight="400"
        desktopLineHeight="36px"
        mobileFontSize={18}
        mobileFontWeight="400"
        mobileLineHeight={"24px"}
        component="h2"
        color={"black"}
      >
        {textLine}
      </NewText>
      <StyledReportsDiv>
        {reportsBlock.map((data: any, index: number) => (
          <TransitionUp
            key={index}
            $inView={inView}
            $delay={index * transitionDelayTime}
          >
            <ReportsWrapper data={data} maintitle={title} />
          </TransitionUp>
        ))}
      </StyledReportsDiv>
    </Container>
  )
}

export default Reports
