import styled from "@emotion/styled"
import Link from "next/link"
import { Pagination } from "swiper"
import "swiper/css"
import "swiper/css/effect-coverflow"
import "swiper/css/effect-fade"
import "swiper/css/pagination"
import { Swiper, SwiperSlide } from "swiper/react"
import { FileIcon2, Mobile, NewText } from "ui"
import { sharedColors } from "ui/theme/colors"
import { Apartment } from "utils"
import { chosenProjectType } from "utils/gtm"

const BodyHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  ${(props) => props.theme.breakpoints.up("md")} {
    gap: 16px;
  }
  .swiper-wrapper {
    margin-bottom: 2rem;
  }
  .swiper-pagination {
    bottom: 0;
    width: max-content;
    left: auto;
    right: auto;
    inset-inline-start: 0;
    .swiper-pagination-bullet {
      width: 6px;
      height: 6px;
      cursor: pointer;
      padding: 0;
      background-color: transparent;
      border-radius: 200px;
      border: 1px solid #000000;
    }
    .swiper-pagination-bullet-active {
      background: black;
    }
  }
`
const StyledDocs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  ${(props) => props.theme.breakpoints.up("md")} {
    gap: 24px;
  }
`

const Document = styled(Link)`
  display: flex;
  align-items: center;
  gap: 8px;
  height: 72px;
  cursor: pointer;
  width: 200px;
  padding: 24px 16px;
  background-color: #ffffff;
  border-radius: 16px;
  text-decoration: none;
  & > span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  ${(props) => props.theme.breakpoints.down("sm")} {
    width: 100%;
  }
`
const IconStyled = styled.div`
  height: 21.5px;
  width: 17.5px;
`

const DocsGrid = styled.div`
  ${(props) => props.theme.breakpoints.down("md")} {
    display: none;
  }
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding-inline-end: 144px;
  width: max-content;
  gap: 20px;
`

export const getFileName = (url: string) => {
  const startName = url.split("/").pop()?.replace(".pdf", "")
  if (!startName) {
    return ""
  }
  let fileName = startName?.slice(0, 25)

  if (fileName?.endsWith("-")) {
    fileName = fileName.slice(0, -1)
  }
  if (fileName?.length < startName?.length) {
    fileName += "..."
  }
  return fileName
}

export default function ApartmentDocs({ apartment }: { apartment: Apartment }) {
  return (
    <StyledDocs>
      <BodyHeader>
        <DocsGrid>
          {apartment.uploadContracts.map((el, i) => (
            <Document
              key={i}
              href={el}
              target="_blank"
              onClick={() => chosenProjectType(getFileName(el), apartment?.propertyTitle, "")}
            >
              <IconStyled>
                <FileIcon2 />
              </IconStyled>
              <NewText
                component={"span"}
                desktopFontSize={16}
                desktopLineHeight={"23.04px"}
                color={sharedColors.black}
                borderBottom={"1px solid black"}
              >
                {getFileName(el)}
              </NewText>
            </Document>
          ))}
        </DocsGrid>
        <Mobile>
          <Swiper
            key={1}
            style={{ padding: 0 }}
            pagination={true}
            modules={[Pagination]}
            slidesPerView={1.4}
            spaceBetween={16}
          >
            {apartment.uploadContracts.map((el, i) => (
              <SwiperSlide key={i}>
                <Document
                  href={el}
                  target="_blank"
                  onClick={() => chosenProjectType(getFileName(el), apartment?.propertyTitle, "")}
                >
                  <IconStyled>
                    <FileIcon2 />
                  </IconStyled>
                  <NewText
                    component={"span"}
                    desktopFontSize={16}
                    desktopLineHeight={"23.04px"}
                    color={sharedColors.black}
                    borderBottom={"1px solid black"}
                  >
                    {getFileName(el)}
                  </NewText>
                </Document>
              </SwiperSlide>
            ))}
          </Swiper>
        </Mobile>
      </BodyHeader>
    </StyledDocs>
  )
}
