"use client"

import styled from "@emotion/styled"
import {
  APIProvider,
  AdvancedMarker,
  Map,
  Pin,
} from "@vis.gl/react-google-maps"
import { Container } from "ui"
import { Address } from "utils/projects"

const APIKEY = "AIzaSyD2AanBqJJ8WM8JL6LJ3jjbYkUgD4Mk0Cc"
const MAPID = "40df340c1a7a4582"
// const position = { lat: 32.109333, lng: 34.855499 }

const StyledPadding = styled.div`
  padding-top: 32px;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding-top: 60px;
  }
`

const StyledMapContainer = styled.div`
  width: 100%;
  height: 328px;
  border-radius: 16px;
  overflow: hidden;
  ${(props) => props.theme.breakpoints.up("md")} {
    height: 500px;
  }
`

type Props = {
  address?: Address
  locale: string
}

function GoogleMap({ address, locale }: Props) {
  let position = { lat: 32.109333, lng: 34.855499 }
  if (address && !address.isEmpty) {
    position = { lat: address.latitude, lng: address.longitude }
  }
  return (
    <APIProvider apiKey={APIKEY} language={locale === "he" ? "iw" : "en"}>
      <Container id="mapContainer">
        <StyledPadding>
          <StyledMapContainer>
            <Map
              defaultZoom={15}
              mapId={MAPID}
              defaultCenter={position}
              disableDefaultUI={true}
            >
              <AdvancedMarker position={position}>
                <Pin
                  // background="grey"
                  // glyphColor={"purple"}
                  borderColor={"green"}
                />
              </AdvancedMarker>
            </Map>
          </StyledMapContainer>
        </StyledPadding>
      </Container>
    </APIProvider>
  )
}

export default GoogleMap
