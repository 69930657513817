import styled from "@emotion/styled"
import { Container, Desktop, Mobile, NewText } from "ui"
import ImageWithAlt from "ui/ImageWithAlt"
import { sharedColors as colors } from "ui/theme/colors"
import { extractImage } from "utils"
import { extractProperty } from "utils/extractProperty"
const StyledImage = styled.div`
  width: 100%;
  height: 492px;
  position: relative;
  ${(props) => props.theme.breakpoints.up("md")} {
    height: 782px;
  }
`

const StyledContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 40px;
  margin-top: 24px;
  ${(props) => props.theme.breakpoints.up("md")} {
    grid-template-columns: 469px 1fr;
    margin: 80px 0 0 0px;
  }
`
const StyledTextContainer = styled.div`
  p {
    margin: 0px;
  }
  h4 {
    margin: 0px;
    margin-top: 40px;
    color: #2768a3;
  }
`
const StyledMargin = styled.div`
  margin-top: 8px;
  ${(props) => props.theme.breakpoints.up("md")} {
    margin-top: 16px;
  }
`

type Props = {
  data: any
}

function BottomTextAndImage({ data }: Props) {
  const dataToRender = data?.propertyValue?.blocks?.[0]
  const mainTitle = extractProperty(dataToRender, "mainTitle")
  const content = extractProperty(dataToRender, "content")
  const subTitle = extractProperty(dataToRender, "subTitle")
  const image = extractImage(dataToRender, "image")
  return (
    <div>
      <Mobile>
        <StyledImage>
          <ImageWithAlt
            image={image}
            fill
            style={{ objectFit: "cover", objectPosition: "top" }}
          />
        </StyledImage>
      </Mobile>
      <Container>
        <StyledContainer>
          <Desktop>
            <StyledImage>
              <ImageWithAlt image={image} fill style={{ objectFit: "cover" }} />
            </StyledImage>
          </Desktop>
          <StyledTextContainer>
            <NewText
              component="p"
              desktopFontSize={40}
              desktopLineHeight="44px"
              mobileFontSize={32}
              mobileLineHeight="36px"
              color={colors.black}
            >
              {mainTitle}
            </NewText>
            <NewText
              component="p"
              desktopFontSize={24}
              desktopLineHeight="34px"
              mobileFontSize={18}
              mobileLineHeight="24px"
              color={colors.black}
              desktopFontWeight={600}
            >
              {subTitle}
            </NewText>
            <StyledMargin>
              <NewText
                component="div"
                desktopFontSize={24}
                desktopLineHeight="34px"
                mobileFontSize={18}
                mobileLineHeight="24px"
                color={colors.black}
                dangerouslySetInnerHTML={{
                  __html: content?.replaceAll("</ br>", ""),
                }}
              />
            </StyledMargin>
          </StyledTextContainer>
        </StyledContainer>
      </Container>
    </div>
  )
}

export default BottomTextAndImage
