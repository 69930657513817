"use client"

import styled from "@emotion/styled"
import { useTranslations } from "next-intl"
import { CheckIcon, NewText } from "ui"
import { sharedColors } from "ui/theme/colors"
import { Apartment } from "utils"

const SpecsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  ${(props) => props.theme.breakpoints.up("md")} {
    gap: 40px;
  }
`

const TopSpecs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  ${(props) => props.theme.breakpoints.up("md")} {
    flex-direction: row;
    gap: 120px;
  }
`
const BodyHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  ${(props) => props.theme.breakpoints.up("md")} {
    gap: 16px;
  }
  .swiper-wrapper {
    margin-bottom: 2rem;
  }
  .swiper-pagination {
    bottom: 0;
    width: max-content;
    left: auto;
    right: auto;
    inset-inline-start: 0;
    .swiper-pagination-bullet {
      width: 6px;
      height: 6px;
      cursor: pointer;
      padding: 0;
      background-color: transparent;
      border-radius: 200px;
      border: 1px solid #000000;
    }
    .swiper-pagination-bullet-active {
      background: black;
    }
  }
`

const CharGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 40px;
  row-gap: 16px;
  ${(props) => props.theme.breakpoints.up("md")} {
    grid-template-columns: repeat(3, auto);
    row-gap: 24px;
  }
`

const Char = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`
type Props = {
  apartment: Apartment
  projectPage?: boolean
}
function ApartmentSpec({ apartment, projectPage }: Props) {
  const t = useTranslations("AccordionDetails")
  return (
    <SpecsContainer>
      <TopSpecs>
        <BodyHeader>
          <NewText
            component={"h4"}
            desktopFontSize={18}
            color={sharedColors.black}
            desktopLineHeight={"22px"}
            mobileFontSize={16}
            desktopFontWeight={600}
            mobileLineHeight={"20px"}
          >
            {t("aptFeatures")}
          </NewText>
          <CharGrid>
            {apartment.apartmentCharacteristics.map((el, i) => (
              <Char key={i}>
                <div>
                  <CheckIcon noCircle={true} />
                </div>
                <NewText
                  component={"span"}
                  desktopFontSize={16}
                  color={sharedColors.black}
                  desktopLineHeight={"20px"}
                  mobileFontSize={14}
                  mobileLineHeight={"20.16px"}
                >
                  {el}
                </NewText>
              </Char>
            ))}
          </CharGrid>
        </BodyHeader>
        {!projectPage && (
          <BodyHeader>
            <NewText
              component={"h4"}
              desktopFontSize={18}
              color={sharedColors.black}
              desktopLineHeight={"22px"}
              mobileFontSize={16}
              desktopFontWeight={600}
              mobileLineHeight={"20px"}
            >
              {t("buildingFeatures")}
            </NewText>
            <CharGrid>
              {apartment.buildingCharacteristics.map((el, i) => (
                <Char key={i}>
                  <div>
                    <CheckIcon noCircle={true} />
                  </div>
                  <NewText
                    component={"span"}
                    desktopFontSize={16}
                    color={sharedColors.black}
                    desktopLineHeight={"20px"}
                    mobileFontSize={14}
                    mobileLineHeight={"20.16px"}
                  >
                    {el}
                  </NewText>
                </Char>
              ))}
            </CharGrid>
          </BodyHeader>
        )}
      </TopSpecs>
    </SpecsContainer>
  )
}
export default ApartmentSpec
