"use client"

import styled from "@emotion/styled"
import { useEffect, useState } from "react"
import { FileIcon2, NewText } from "ui"
import { sharedColors as colors } from "ui/theme/colors"
import { FileState } from "./FormComponent"

const StyledBox = styled.div`
  display: flex;
  max-width: 600px;
  height: 457px;
  padding: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
`
const StyledProgressContainer = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: end;
  gap: 8px;
  align-self: stretch;
  border-radius: 16px;
  border: 1px solid #f7f7f7;
  background: #fff;
`

const StyledProgressLine = styled.div`
  display: flex;
  height: 8px;
  width: 100%;
  align-items: end;
  align-self: stretch;
  border-radius: 20px;
  background: #f7f7f7;
  justify-content: end;
`
const StyledProgress = styled.div<{ percent: number }>`
  display: flex;
  height: 8px;
  width: ${(props) => props.percent}%;
  align-items: center;
  border-radius: 20px;
  background: #008015;
`
const StyledDetails = styled.div`
  display: flex;
  justify-content: space-between;
  align-self: stretch;
  width: 100%;
`

type Props = {
  filename: string | null
  filesize: number
  setProgress: React.Dispatch<React.SetStateAction<boolean>>
  setFileState: React.Dispatch<React.SetStateAction<FileState>>
}

function getRandomInteger(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min + 1)) + min
}

function UploadProgress({ filename, setProgress, filesize, setFileState }: Props) {
  // eslint-disable-next-line no-unused-vars
  const [_, setStatus] = useState<"Running" | "Finished" | null>(null)
  const [percent, setPercent] = useState<number>(0)
  useEffect(() => {
    const interval = setInterval(() => {
      setPercent((prevProgress) => {
        const newProgress = prevProgress + getRandomInteger(1, 10)
        if (newProgress >= 100) {
          clearInterval(interval)
          return 100
        }
        return newProgress
      })
    }, 700)

    return () => clearInterval(interval)
  }, [])
  useEffect(() => {
    if (percent === 100) {
      setFileState({ file: null, fileError: null, fileName: null, fileSize: 0 })
      setStatus("Finished")
      setProgress(false)
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [percent])
  return (
    <StyledBox>
      <FileIcon2 />
      <NewText component={"h3"} desktopFontSize={32} color={colors.black}>
        מעלים את קו״ח שלך
      </NewText>
      <StyledProgressContainer>
        <NewText component={"span"} desktopFontSize={18} color={colors.black}>
          {filename}
        </NewText>
        <StyledProgressLine>
          <StyledProgress percent={percent}>&#8203;</StyledProgress>
        </StyledProgressLine>
        <StyledDetails>
          <NewText component={"span"} desktopFontSize={18} color={colors.black}>
            {percent}%
          </NewText>
          <NewText component={"span"} desktopFontSize={18} color={colors.black}>
            {(filesize / (1024 * 1024)).toFixed(2)}MB
          </NewText>
        </StyledDetails>
      </StyledProgressContainer>
    </StyledBox>
  )
}

export default UploadProgress
