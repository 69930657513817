"use client"

import styled from "@emotion/styled"
import { useLocale } from "next-intl"
import Link from "next/link"
import { Container, NewText, NotFouncIcon } from "ui"
import { sharedColors as colors } from "ui/theme/colors"

const StyledPageDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  height: 540px;
  h1 {
    margin-top: 16px;
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    height: 660px;
    h1 {
      margin-top: 40px;
    }
  }
`

const StyledLink = styled(Link)`
  width: 100%;
  height: 48px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 16px;
`

const StyledLinkDiv = styled.div`
  width: auto;
  height: 48px;
  border: 2px solid black;
  border-radius: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 32px;
  ${(props) => props.theme.breakpoints.up("md")} {
    margin-top: 40px;
  }
`

function NotFoundPage() {
  const locale = useLocale()
  return (
    <Container>
      <StyledPageDiv>
        <NewText
          component={"p"}
          desktopFontSize={60}
          desktopFontWeight={600}
          desktopLineHeight={"44px"}
          mobileFontSize={60}
          mobileLineHeight={"36px"}
          color={colors.black}
          style={{ marginBottom: "40px" }}
        >
          404
        </NewText>
        <NotFouncIcon />
        <NewText
          component={"h1"}
          desktopFontSize={40}
          desktopFontWeight={400}
          desktopLineHeight={"44px"}
          mobileFontSize={32}
          mobileLineHeight={"36px"}
          color={colors.black}
        >
          {locale === "en"
            ? "You've reached a page we haven't built yet"
            : "‎הגעת לעמוד שעדיין לא בנינו"}
        </NewText>
        <StyledLinkDiv>
          <StyledLink href={"/"}>
            <NewText
              component={"p"}
              desktopFontSize={18}
              desktopLineHeight={"22px"}
              desktopFontWeight={600}
              color={colors.black}
            >
              {locale === "en" ? "Back to home page" : "חזרה לעמוד הבית"}
            </NewText>
          </StyledLink>
        </StyledLinkDiv>
      </StyledPageDiv>
    </Container>
  )
}

export default NotFoundPage
