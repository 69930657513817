"use client"

import styled from "@emotion/styled"
import {
  APIProvider,
  AdvancedMarker,
  Map,
  Pin,
} from "@vis.gl/react-google-maps"

const APIKEY = "AIzaSyD2AanBqJJ8WM8JL6LJ3jjbYkUgD4Mk0Cc"
const MAPID = "40df340c1a7a4582"
// const position = { lat: 32.109333, lng: 34.855499 }

const StyledMapContainer = styled.div`
  width: 100%;
  height: 328px;
  border-radius: 16px;
  overflow: hidden;
  ${(props) => props.theme.breakpoints.up("md")} {
    height: 100%;
  }
`

type Props = {
  address?: { latitude: number; longitude: number }
  locale: string
}

function MiniMap({ address, locale }: Props) {
  let position = { lat: 32.109333, lng: 34.855499 }
  if (address) {
    position = { lat: address.latitude, lng: address.longitude }
  }
  return (
    <APIProvider apiKey={APIKEY} language={locale === "he" ? "iw" : "en"}>
      <StyledMapContainer>
        <Map
          defaultZoom={12}
          mapId={MAPID}
          defaultCenter={position}
          disableDefaultUI={true}
        >
          <AdvancedMarker position={position}>
            <Pin
              // background="grey"
              // glyphColor={"purple"}
              borderColor={"green"}
            />
          </AdvancedMarker>
        </Map>
      </StyledMapContainer>
    </APIProvider>
  )
}

export default MiniMap
