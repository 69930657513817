import styled from "@emotion/styled"
import { IconButton } from "@mui/material"
import { useLocale } from "next-intl"
import { useState } from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import { Container, LeftArrow, RightArrow } from "ui"
import ImageWithAlt from "ui/ImageWithAlt"
import { sharedColors } from "ui/theme/colors"
import { extractImage, extractProperty } from "utils"

const StyledBottomContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 11px;
  left: 8px;
  background-color: #fff;
  border-radius: 200px;
  z-index: 999;
  ${(props) => props.theme.breakpoints.up("md")} {
    transform: translate(-50%, 0);

    bottom: 24px;
    left: 50%;
  }
`
const StyledPaginatorContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const StyledBulletsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 0 2px;

  ${(props) => props.theme.breakpoints.up("md")} {
    margin: 0 4px;
  }
`

const StyledBullet = styled.div<{ active: boolean }>`
  width: 6px;
  box-sizing: border-box;
  height: 6px;
  border-radius: 50%;
  margin: 0 2px;
  cursor: pointer;
  background-color: ${(props) =>
    props.active ? sharedColors.black : "transparent"};
  border: 1px solid #000;
`

const StyledContainer = styled.div`
  padding: 16px 0px;
  .swiper {
    padding: 0px !important;
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    padding: 24px 0px;
  }
`

const StyledRelative = styled.div`
  position: relative;
`

const StyledIconButton = styled(IconButton)`
  height: 26px;
`

const StyledImage = styled.div`
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  height: 260px;
  ${(props) => props.theme.breakpoints.up("md")} {
    border-radius: 16px;
    height: 500px;
  }
`

type Props = {
  data: any
}

function DynamicGallery({ data }: Props) {
  const locale = useLocale()
  const galleryItems = extractProperty(data, "slideElements")
  const [swiperRef, setSwiperRef] = useState<any>(null)
  const [activeIndex, setActiveIndex] = useState<number>(0)
  return (
    <StyledContainer>
      <Container>
        <StyledRelative>
          <Swiper
            onSwiper={setSwiperRef}
            onSlideChange={(swiper) => {
              setActiveIndex(swiper.activeIndex)
            }}
          >
            {galleryItems.map((item: any, index: number) => {
              const desktopImage = extractImage(item, "imageOrVideoSelsctor")
              return (
                <SwiperSlide virtualIndex={index} key={index.toString()}>
                  <StyledImage>
                    <ImageWithAlt image={desktopImage} fill />
                  </StyledImage>
                </SwiperSlide>
              )
            })}
          </Swiper>

          <StyledBottomContainer>
            <StyledPaginatorContainer>
              <StyledIconButton
                onClick={() => {
                  swiperRef.slidePrev()
                }}
              >
                {locale === "he" ? <RightArrow /> : <LeftArrow />}
              </StyledIconButton>
              <StyledBulletsContainer>
                {galleryItems.map((_: any, index: number) => (
                  <StyledBullet
                    key={index.toString()}
                    onClick={() => {
                      swiperRef.slideTo(index)
                    }}
                    active={index === activeIndex}
                  />
                ))}
              </StyledBulletsContainer>
              <StyledIconButton
                onClick={() => {
                  swiperRef.slideNext()
                }}
              >
                {locale === "he" ? <LeftArrow /> : <RightArrow />}
              </StyledIconButton>
            </StyledPaginatorContainer>
          </StyledBottomContainer>
        </StyledRelative>
      </Container>
    </StyledContainer>
  )
}

export default DynamicGallery
