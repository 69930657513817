"use client"

import { GetPageByRouteQuery } from "apollo"
import {
  BlockRenderer,
  Container,
  ContentText,
  CustomBreadcrumbs,
  History,
  NumbersComponent,
  TopBanner,
} from "ui"
import { getBreadCrumbs } from "utils"
import { findProperty } from "utils/findProperty"
import ActiveArms from "./ActiveArms"
import TeamContainer from "./TeamContainer"

type Props = {
  data: GetPageByRouteQuery
  locale: string
  messages: { learnMore: string; followUs: string; learnMoreAbout: string }
}

function AboutUs({ data, locale, messages }: Props) {
  const { selfObject, parents } = getBreadCrumbs(data, locale)
  const components = {
    topBanner: TopBanner,
    contentText: ContentText,
    numbersComponent1: NumbersComponent,
    activeArms: ActiveArms,
    teamContainer: TeamContainer,
    historySlider: History,
  }
  const blocks = findProperty(data.page, "blocks")?.blocks || []
  const hasBanner = blocks?.some(
    (block: any) => block.blockType === "topBanner" || block.blockType === "HeaderWithHeroAndText",
  )
  return (
    <>
      <Container>
        <CustomBreadcrumbs current={selfObject} parents={parents} />
      </Container>
      {blocks?.map((block: any, index: number) => {
        let additionalProps = {}
        if (block.blockType === "numbersComponent1") {
          additionalProps = { accentColor: "black" }
        } else if (block.blockType === "teamContainer") {
          additionalProps = { followMessage: messages.followUs }
        } else if (block.blockType === "historySlider") {
          additionalProps = { learnMore: messages.learnMore }
        } else if (block.blockType === "contentText") {
          additionalProps = { underline: true, marginBottom: "0px", hasBanner }
        }
        return (
          <BlockRenderer
            key={index}
            blockType={block.blockType}
            blockData={block}
            components={components}
            props={additionalProps}
            locale={locale}
          />
        )
      })}
    </>
  )
}

export default AboutUs
