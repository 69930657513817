import styled from "@emotion/styled"
import { Container, NewText } from "ui"
import { sharedColors } from "ui/theme/colors"

const StyledNote = styled.div`
  padding-bottom: 32px;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding-bottom: 60px 0;
  }
`
const InnerDiv = styled.div`
  padding-top: 24px;
  ${(props) => props.theme.breakpoints.up("md")} {
    width: 70%;
  }
`

const StyledDivider = styled.div`
  display: block;
  width: 100%;
  height: 1px;
  background: ${sharedColors.black};
`

function LegalNote({ text }: { text: string }) {
  if (!text) {
    return null
  }
  return (
    <Container>
      <StyledNote>
        {text && (
          <>
            <StyledDivider />
            <InnerDiv>
              <NewText
                component={"p"}
                desktopFontSize={16}
                desktopLineHeight={"normal"}
                mobileFontSize={14}
                mobileLineHeight={"normal"}
                color="#6F6F6F"
                dangerouslySetInnerHTML={{ __html: text || "" }}
              />
            </InnerDiv>
          </>
        )}
      </StyledNote>
    </Container>
  )
}

export default LegalNote
