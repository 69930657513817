"use client"

import styled from "@emotion/styled"
import { useTranslations } from "next-intl"
import { FormHelperErrorIcon, FormHelperValidIcon } from "ui"

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

type Props = {
  render: boolean
  error: boolean
  text?: string
  locale?: string
}

function CustomHelperText({ render, error, text }: Props) {
  const t = useTranslations("ProjectsPage")
  if (!render) return null
  return (
    <StyledContainer>
      {error ? <FormHelperErrorIcon /> : <FormHelperValidIcon />}
      <p
        style={{
          margin: 0,
          width: "100%",
          color: error ? "#C92418" : "#008015",
          marginRight: "4px",
        }}
      >
        {text || t("completed")}
      </p>
    </StyledContainer>
  )
}

export default CustomHelperText
