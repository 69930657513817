import styled from "@emotion/styled"
import { useLocale, useTranslations } from "next-intl"
import { CheckIcon, NewText } from "ui"
import { sharedColors } from "ui/theme/colors"
import { ApartmentUmbraco } from "utils/projects"

const apartmentCharacteristics: Record<string, string> = {
  מרפסת: "Balcony",
  מחסן: "Storage Room",
  חניה: "Parking",
  "מיזוג מרכזי": "Central Air",
  // eslint-disable-next-line quotes
  'ממ"ד': "Residental Secure Place",
  "מיזוג עילי": "Top Air Conditioner",
  "דוד שמש": "Water Heater",
  סורגים: "Window Bars",
  "ועד בית": "HOA",
}

const SpecsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  ${(props) => props.theme.breakpoints.up("md")} {
    gap: 40px;
  }
`

const TopSpecs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  ${(props) => props.theme.breakpoints.up("md")} {
    flex-direction: row;
    gap: 120px;
  }
`
const BodyHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  ${(props) => props.theme.breakpoints.up("md")} {
    gap: 16px;
  }
  .swiper-wrapper {
    margin-bottom: 2rem;
  }
  .swiper-pagination {
    bottom: 0;
    width: max-content;
    left: auto;
    right: auto;
    inset-inline-start: 0;
    .swiper-pagination-bullet {
      width: 6px;
      height: 6px;
      cursor: pointer;
      padding: 0;
      background-color: transparent;
      border-radius: 200px;
      border: 1px solid #000000;
    }
    .swiper-pagination-bullet-active {
      background: black;
    }
  }
`

const Content = styled.div`
  width: 100%;
  ${(props) => props.theme.breakpoints.up("md")} {
    width: 70%;
  }
  p > p:first-child {
    margin-top: 0;
  }
  p > p:last-child {
    margin-bottom: 0;
  }
`

const CharGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 40px;
  row-gap: 16px;
  ${(props) => props.theme.breakpoints.up("md")} {
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 24px;
  }
`

const Char = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`
type Props = {
  apartment: ApartmentUmbraco
}
function ApartmentSpec({ apartment }: Props) {
  const t = useTranslations("AccordionDetails")
  const locale = useLocale()

  return (
    <SpecsContainer>
      <TopSpecs>
        <BodyHeader>
          <NewText
            component={"h4"}
            desktopFontSize={18}
            color={sharedColors.black}
            desktopLineHeight={"22px"}
            mobileFontSize={16}
            desktopFontWeight={600}
            mobileLineHeight={"20px"}
          >
            {t("aptFeatures")}
          </NewText>
          <CharGrid>
            {apartment.apartmentCharacteristics.map((el, i) => (
              <Char key={i}>
                <CheckIcon noCircle={true} />
                <NewText
                  component={"span"}
                  desktopFontSize={16}
                  color={sharedColors.black}
                  desktopLineHeight={"20px"}
                  mobileFontSize={14}
                  mobileLineHeight={"20.16px"}
                >
                  {locale === "he"
                    ? el
                    : apartmentCharacteristics[el as keyof typeof apartmentCharacteristics]}
                </NewText>
              </Char>
            ))}
          </CharGrid>
        </BodyHeader>
      </TopSpecs>
      <BodyHeader>
        <NewText
          component={"h4"}
          desktopFontSize={18}
          color={sharedColors.black}
          desktopLineHeight={"22px"}
          mobileFontSize={16}
          desktopFontWeight={600}
          mobileLineHeight={"20px"}
        >
          {t("moreInfo")}
        </NewText>
        <Content>
          <NewText
            component={"p"}
            desktopFontSize={16}
            color={sharedColors.black}
            desktopLineHeight={"25.92px"}
            mobileFontSize={16}
            mobileLineHeight={"20px"}
            dangerouslySetInnerHTML={{
              __html: apartment.moreInfoContentHTML || "",
            }}
          />
        </Content>
      </BodyHeader>
    </SpecsContainer>
  )
}
export default ApartmentSpec
