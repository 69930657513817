"use client"
import styled from "@emotion/styled"
import { IconButton, Modal } from "@mui/material"
import moment from "moment"
import Image from "next/image"
import { useState } from "react"
import { Desktop, Mobile, NewText, WhiteCircleIcon } from "ui"
import { getUmbracoMediaUrl } from "utils"

const StyledContainer = styled.div`
  padding: 8px;
  border-radius: 16px;
  box-shadow: 0px 4px 12px 0px #00000029;

  margin-bottom: 24px;
  ${(props) => props.theme.breakpoints.up("md")} {
    margin: 0;
  }
  .MuiModal-root {
    z-index: 99999 !important;
  }
`

const StyledInnerContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  align-items: center;
  ${(props) => props.theme.breakpoints.up("md")} {
    flex-direction: column;
    display: flex;
    gap: 0px;
  }
`
const StyledTopContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`

const StyledDateContainer = styled.div`
  display: flex;
  align-items: center;
`

const StyledImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 112px;
`

const StyledTextWrapper = styled.div``

const StyledModal = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  ${(props) => props.theme.breakpoints.up("md")} {
    flex-direction: row-reverse;
    gap: 97px;
    > div {
      height: 50%;
    }
  }
`

const StyledModalImage = styled.div`
  position: relative;
  width: 100%;
  height: 180px;
  img {
    object-fit: contain;
    ${(props) => props.theme.breakpoints.up("md")} {
      object-fit: cover;
    }
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    height: 100%;
  }
`

const StyledModalContent = styled.div`
  width: 100%;
  height: 100%;
  ${(props) => props.theme.breakpoints.down("sm")} {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: start;
    margin-top: 80px;
    p {
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    width: 50%;
    height: 50%;
  }
`

const StyledSvgButton = styled(IconButton)``

const StyledButtonWrapper = styled.div`
  ${(props) => props.theme.breakpoints.up("md")} {
    height: 50%;
  }
`

const StyledDiv = styled.div`
  width: 100%;
  margin-top: 90px;
  ${(props) => props.theme.breakpoints.up("md")} {
    height: 100%;
    margin-top: 0px;
  }
`

function StandardCard(props: any) {
  const { title, date, content, image, logo } = props
  const [open, setOpen] = useState(false)

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <StyledContainer>
        <Mobile>
          <StyledInnerContainer>
            <StyledImageContainer>
              <Image
                src={getUmbracoMediaUrl(logo)}
                layout="fill"
                alt="logo"
                style={{
                  objectFit: "contain",
                }}
              />
            </StyledImageContainer>
            <StyledTextWrapper>
              <NewText
                desktopFontSize={32}
                desktopFontWeight="400"
                desktopLineHeight="36px"
                mobileFontSize={18}
                mobileFontWeight="400"
                mobileLineHeight="1.2"
                component="div"
                color={"black"}
                onClick={() => setOpen(true)}
                style={{
                  borderBottom: "1px solid black",
                  width: "max-content",
                  cursor: "pointer",
                }}
              >
                {title}
              </NewText>
              {date && date.length > 0 && (
                <StyledDateContainer>
                  <NewText
                    desktopFontSize={20}
                    desktopFontWeight="400"
                    desktopLineHeight="24px"
                    mobileFontSize={16}
                    mobileFontWeight="400"
                    mobileLineHeight="20px"
                    component="div"
                    color={"black"}
                    style={{ marginTop: "16px" }}
                  >
                    {moment(date).format("MM/YYYY")}
                  </NewText>
                </StyledDateContainer>
              )}
            </StyledTextWrapper>
          </StyledInnerContainer>
        </Mobile>
        <Desktop>
          <StyledInnerContainer>
            <StyledTopContainer>
              <StyledImageContainer>
                <Image
                  src={getUmbracoMediaUrl(logo)}
                  layout="fill"
                  alt="logo"
                  style={{
                    objectFit: "contain",
                    objectPosition: "top",
                    borderRadius: "8px",
                  }}
                />
              </StyledImageContainer>
              <NewText
                desktopFontSize={20}
                desktopFontWeight="400"
                desktopLineHeight="1.2"
                mobileFontSize={18}
                mobileFontWeight="400"
                mobileLineHeight="1.2"
                component="div"
                color={"black"}
                onClick={() => setOpen(true)}
                style={{
                  borderBottom: "1px solid black",
                  marginTop: "8px",
                  cursor: "pointer",
                }}
              >
                {title}
              </NewText>
              {date && date.length > 0 && (
                <StyledDateContainer>
                  <NewText
                    desktopFontSize={18}
                    desktopFontWeight="400"
                    desktopLineHeight="1.2"
                    mobileFontSize={18}
                    mobileFontWeight="400"
                    mobileLineHeight="1.2"
                    component="div"
                    color={"black"}
                    style={{ marginTop: "16px" }}
                  >
                    {moment(date).format("MM/YYYY")}
                  </NewText>
                </StyledDateContainer>
              )}
            </StyledTopContainer>
          </StyledInnerContainer>
        </Desktop>
      </StyledContainer>
      <Modal
        open={open}
        onClose={() => {
          handleClose()
        }}
        style={{ zIndex: "99999", background: "rgba(0, 0, 0, 0.7)" }}
      >
        <StyledModal>
          <Desktop>
            <StyledButtonWrapper>
              <StyledSvgButton onClick={() => handleClose()}>
                <WhiteCircleIcon />
              </StyledSvgButton>
            </StyledButtonWrapper>
          </Desktop>
          <StyledModalContent>
            <Mobile>
              <StyledButtonWrapper>
                <StyledSvgButton onClick={() => handleClose()}>
                  <WhiteCircleIcon />
                </StyledSvgButton>
              </StyledButtonWrapper>
            </Mobile>
            <StyledDiv>
              <Desktop>
                <StyledModalImage
                  style={{
                    width: "100%",
                    height: "435px",
                  }}
                >
                  <Image
                    src={getUmbracoMediaUrl(image)}
                    layout="fill"
                    alt="logo"
                    objectFit="contain"
                  />
                </StyledModalImage>
              </Desktop>
              <Mobile>
                <StyledModalImage>
                  <Image
                    src={getUmbracoMediaUrl(image)}
                    layout="fill"
                    alt="logo"
                    objectFit="contain"
                    objectPosition="top"
                  />
                </StyledModalImage>
              </Mobile>

              <NewText
                component={"p"}
                desktopFontSize={24}
                desktopLineHeight={"35px"}
                desktopFontWeight={400}
                mobileLineHeight={"26px"}
                color="white"
                mobileFontSize={18}
                style={{ marginTop: "40px" }}
                dangerouslySetInnerHTML={{ __html: content }}
              />
            </StyledDiv>
          </StyledModalContent>
        </StyledModal>
      </Modal>
    </>
  )
}

export default StandardCard
