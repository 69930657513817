import styled from "@emotion/styled"
import StockDto from "./StockDto"

import { StockDtoGraph } from "ui"
import { InvestoreRelationsMessages } from "./InvestorsRelation"

const StyledDiv = styled.div`
  margin-top: 24px;
  ${(props) => props.theme.breakpoints.up("md")} {
    margin-top: 40px;
  }
`

const StyledBottomDiv = styled.div``

type Props = {
  data: any
  type?: string
  messages: InvestoreRelationsMessages
}

function Stock({ data, type, messages }: Props) {
  return (
    <StyledDiv>
      <StockDtoGraph data={data?.grapStocksDtos} />
      {type !== "inner" && (
        <StyledBottomDiv>
          <StockDto data={data?.stockDto} messages={messages} />
        </StyledBottomDiv>
      )}
    </StyledDiv>
  )
}

export default Stock
