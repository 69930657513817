"use client"

import { Container, useIsDesktop } from "ui"
import Slider from "../Slider"

type Props = {
  locale: string
  gallery: Array<{
    imageOrVideoSelectorMobaile?: string
    imageOrVideoSelsctor?: string
  }>
}

function Gallery({ gallery, locale }: Props) {
  const isDesktop = useIsDesktop()

  return (
    <Container
      style={{
        paddingTop: isDesktop ? "60px" : "32px",
      }}
    >
      {gallery && gallery.length > 0 ? (
        <Slider gallery={gallery} locale={locale} />
      ) : null}
    </Container>
  )
}

export default Gallery
