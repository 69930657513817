import styled from "@emotion/styled"

const StyledCard = styled.div`
  width: 100%;
  min-height: 308px;
  border-radius: 16px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border: 1px solid #6f6f6f;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 1000px 104px;
  animation-duration: 1.2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shimmer;
  animation-timing-function: linear;
  ${(props) => props.theme.breakpoints.up("md")} {
    min-height: 298px;
  }
`

function JobSkeleton() {
  return <StyledCard></StyledCard>
}

export default JobSkeleton
