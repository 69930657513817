"use client"

import styled from "@emotion/styled"
import { Container, NewText, useIsDesktop } from "ui"
import { sharedColors as colors } from "ui/theme/colors"
import { Groups } from "utils"
import PartnerSlider from "./PartnerSlider"

const StyledIntro = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`
const StyledGroups = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  ${(props) => props.theme.breakpoints.up("md")} {
    gap: 60px;
  }
  padding-top: 24px;
`
const PartnerBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`
function Partners({
  groups,
  subTitle,
  companyText,
  messages,
}: {
  groups: Groups
  subTitle?: string
  companyText?: string
  messages: {
    groups: string
    collaborators: string
    entr: string
  }
}) {
  const isDesktop = useIsDesktop()
  if (
    !groups.companies.length &&
    !groups.entrepreneurs.length &&
    !groups.partners.length
  ) {
    return null
  }
  return (
    <Container
      style={{
        paddingTop: isDesktop ? "60px" : "32px",
      }}
    >
      <StyledIntro>
        <NewText
          component={"h3"}
          desktopFontSize={32}
          color={colors.black}
          desktopLineHeight={"36px"}
          mobileLineHeight={"28px"}
        >
          {subTitle}
        </NewText>
        <NewText
          component={"span"}
          desktopFontSize={24}
          color={colors.black}
          desktopLineHeight={"35px"}
          mobileLineHeight={"24px"}
          mobileFontSize={18}
        >
          {companyText}
        </NewText>
      </StyledIntro>
      <StyledGroups>
        {groups.companies.length > 0 && (
          <PartnerBox>
            <NewText
              component={"h3"}
              desktopFontSize={24}
              color={colors.black}
              desktopLineHeight={"35px"}
              mobileLineHeight={"24px"}
              mobileFontSize={18}
            >
              {messages.groups}
            </NewText>
            <PartnerSlider data={groups.companies} />
          </PartnerBox>
        )}
        {groups.entrepreneurs.length > 0 ? (
          <PartnerBox>
            <NewText
              component={"h3"}
              desktopFontSize={24}
              color={colors.black}
              desktopLineHeight={"35px"}
              mobileLineHeight={"24px"}
              mobileFontSize={18}
              desktopFontWeight={600}
            >
              {messages.entr}
            </NewText>
            <PartnerSlider data={groups.entrepreneurs} />
          </PartnerBox>
        ) : null}
        {groups.partners.length > 0 ? (
          <PartnerBox>
            <NewText
              component={"h3"}
              desktopFontSize={24}
              color={colors.black}
              desktopLineHeight={"35px"}
              mobileLineHeight={"24px"}
              mobileFontSize={18}
              desktopFontWeight={600}
            >
              {messages.collaborators}
            </NewText>

            <PartnerSlider data={groups.partners} textOnly />
          </PartnerBox>
        ) : null}
      </StyledGroups>
    </Container>
  )
}

export default Partners
