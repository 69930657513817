import styled from "@emotion/styled"
import { Box, Button } from "@mui/material"
import Link from "next/link"
import { Container, NewText } from "ui"
import ImageWithAlt from "ui/ImageWithAlt"
import { sharedColors as colors } from "ui/theme/colors"
import { extractImage, extractProperty } from "utils"
import { actionButtonClick } from "utils/gtm"
const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 32px;
  margin-top: 32px;

  align-items: center;
  justify-items: center;
  margin-bottom: 32px;
  ${(props) => props.theme.breakpoints.up("md")} {
    margin-top: 40px;
    margin-bottom: 80px;
    grid-gap: 80px;
    grid-template-columns: 1fr 424px;
  }
`
const StyledImage = styled.div`
  grid-row: -1 / 1;

  width: 240px;
  height: 240px;
  position: relative;
  display: none;
  ${(props) => props.theme.breakpoints.up("md")} {
    grid-row: auto;
    display: block;
    width: 424px;
    height: 424px;
  }
`
const StyledLink = styled(Link)`
  text-decoration: none;
  border: 2px solid black;
  padding: 8px 16px 8px 16px;
  border-radius: 8px;
  button {
    text-decoration: none;
  }

  :hover {
    text-decoration: none;
  }
  button {
    font-size: 20px;
  }
`
type Props = { data: any; children: React.ReactNode }

function TopContent({ data, children }: Props) {
  const dataToRender = data?.propertyValue?.blocks?.[0]
  const mainTitle = extractProperty(dataToRender, "mainTitle")
  const content = extractProperty(dataToRender, "content")
  const button = extractProperty(dataToRender, "button")
  const image = extractImage(dataToRender, "image")
  const buttonName = button?.[0]?.name
  return (
    <Container>
      <StyledGrid>
        <Box>
          {children}
          <Box>
            <NewText
              component="h1"
              desktopFontSize={40}
              desktopLineHeight="44px"
              mobileFontSize={32}
              mobileLineHeight="36px"
              color={colors.black}
            >
              {mainTitle}
            </NewText>
            <NewText
              component="div"
              desktopFontSize={24}
              desktopLineHeight="34.92px"
              mobileFontSize={18}
              mobileLineHeight="25px"
              dangerouslySetInnerHTML={{ __html: content }}
              color={colors.black}
            />
            {buttonName && (
              <StyledLink href={button?.[0]?.url || ""}>
                <Button
                  onClick={() => actionButtonClick(buttonName, mainTitle)}
                >
                  {buttonName}
                </Button>
              </StyledLink>
            )}
          </Box>
        </Box>
        <StyledImage>
          <ImageWithAlt image={image} fill />
        </StyledImage>
      </StyledGrid>
    </Container>
  )
}

export default TopContent
