import styled from "@emotion/styled"
import { IconButton } from "@mui/material"
import { useTranslations } from "next-intl"
import Image from "next/image"
import { useState } from "react"
import { GalleryIcon, NewText, XIcon } from "ui"
import { sharedColors } from "ui/theme/colors"
import { Apartment, getUmbracoMediaUrl } from "utils"
import {
  StyledCloseDiv,
  StyledContainer,
  StyledExpandIcon,
  StyledGalleryPopup,
  StyledImagesDiv,
  StyledMainImage,
} from "./ApartmentGallery"
import MiniMap from "./MiniMap"

const StyledPlanDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  ${(props) => props.theme.breakpoints.up("md")} {
    flex-direction: row;
    gap: 24px;
  }
`

const StyledBluePrintDiv = styled.div`
  height: 280px;
  background-color: white;
  border-radius: 16px;
  width: 100%;
  position: relative;
  ${(props) => props.theme.breakpoints.up("md")} {
    height: 480px;
    width: 65%;
  }
`

const StyledMoreInfo = styled.div`
  background-color: white;
  border-radius: 16px;
  padding: 16px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const RightSide = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  ${(props) => props.theme.breakpoints.up("md")} {
    width: 35%;
  }
`

const StyledValueDiv = styled.div`
  min-height: 39px;
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #f7f7f7;
  &:last-child {
    border-bottom: none;
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    min-height: 42px;
  }
`

const MapContainer = styled.div`
  height: 100%;
`
type Props = {
  apartment: Apartment
  projectPage?: boolean
}

function ApartmentPlan({ apartment, projectPage }: Props) {
  const t = useTranslations("AccordionDetails")
  const [open, setOpen] = useState(false)

  return (
    <StyledPlanDiv>
      <StyledBluePrintDiv>
        {apartment.blueprintOfTheApartment && (
          <>
            <Image
              src={getUmbracoMediaUrl(apartment.blueprintOfTheApartment)}
              alt="poster 1"
              layout="fill"
              objectFit="contain"
            />
            <StyledExpandIcon>
              <IconButton onClick={() => setOpen(true)}>
                <GalleryIcon />
              </IconButton>
            </StyledExpandIcon>
            {open && (
              <StyledGalleryPopup>
                <StyledContainer>
                  <StyledCloseDiv>
                    <IconButton onClick={() => setOpen(false)}>
                      <XIcon />
                    </IconButton>
                  </StyledCloseDiv>
                  <StyledImagesDiv>
                    <StyledMainImage heightMargin={200}>
                      <Image
                        src={getUmbracoMediaUrl(apartment.blueprintOfTheApartment)}
                        layout="fill"
                        objectFit="cover"
                        alt="poster"
                      />
                    </StyledMainImage>
                  </StyledImagesDiv>
                </StyledContainer>
              </StyledGalleryPopup>
            )}
          </>
        )}
      </StyledBluePrintDiv>
      <RightSide>
        <StyledMoreInfo>
          <NewText
            component={"p"}
            desktopFontSize={20}
            desktopLineHeight={"28px"}
            desktopFontWeight={600}
            mobileFontSize={18}
            mobileLineHeight={"25px"}
            color={sharedColors.black}
          >
            {t("moreInformation")}
          </NewText>
          <StyledValueDiv>
            <NewText
              component={"p"}
              desktopFontSize={18}
              desktopLineHeight={"26px"}
              desktopFontWeight={400}
              mobileFontSize={16}
              mobileLineHeight={"23px"}
              color={sharedColors.black}
            >
              {t("building")}
            </NewText>
            <NewText
              component={"p"}
              desktopFontSize={18}
              desktopLineHeight={"26px"}
              desktopFontWeight={400}
              mobileFontSize={16}
              mobileLineHeight={"23px"}
              color={sharedColors.black}
            >
              {apartment.buildingTitle}
            </NewText>
          </StyledValueDiv>
          <StyledValueDiv>
            <NewText
              component={"p"}
              desktopFontSize={18}
              desktopLineHeight={"26px"}
              desktopFontWeight={400}
              mobileFontSize={16}
              mobileLineHeight={"23px"}
              color={sharedColors.black}
            >
              {t("apartmentNumber")}
            </NewText>
            <NewText
              component={"p"}
              desktopFontSize={18}
              desktopLineHeight={"26px"}
              desktopFontWeight={400}
              mobileFontSize={16}
              mobileLineHeight={"23px"}
              color={sharedColors.black}
            >
              {apartment.apartmentNumber}
            </NewText>
          </StyledValueDiv>
          <StyledValueDiv>
            <NewText
              component={"p"}
              desktopFontSize={18}
              desktopLineHeight={"26px"}
              desktopFontWeight={400}
              mobileFontSize={16}
              mobileLineHeight={"23px"}
              color={sharedColors.black}
            >
              {t("direction")}
            </NewText>
            <NewText
              component={"p"}
              desktopFontSize={18}
              desktopLineHeight={"26px"}
              desktopFontWeight={400}
              mobileFontSize={16}
              mobileLineHeight={"23px"}
              color={sharedColors.black}
            >
              {apartment.orientation}
            </NewText>
          </StyledValueDiv>
          <StyledValueDiv>
            <NewText
              component={"p"}
              desktopFontSize={18}
              desktopLineHeight={"26px"}
              desktopFontWeight={400}
              mobileFontSize={16}
              mobileLineHeight={"23px"}
              color={sharedColors.black}
            >
              {t("boardfee")}
            </NewText>
            <NewText
              component={"p"}
              desktopFontSize={18}
              desktopLineHeight={"26px"}
              desktopFontWeight={400}
              mobileFontSize={16}
              mobileLineHeight={"23px"}
              color={sharedColors.black}
            >
              {apartment.houseCommitee} ₪
            </NewText>
          </StyledValueDiv>
        </StyledMoreInfo>
        {!projectPage && (
          <MapContainer>
            <MiniMap locale="he" address={apartment.location} />
          </MapContainer>
        )}
      </RightSide>
    </StyledPlanDiv>
  )
}

export default ApartmentPlan
