"use client"

import styled from "@emotion/styled"
import { AllProjects, ProjectFilters } from "utils/projects"
import ProjectCard from "./ProjectCard"

const StyledGrid = styled.div`
  display: grid;
  column-gap: 24px;
  row-gap: 40px;
  grid-template-columns: 1fr;
  ${(props) => props.theme.breakpoints.up("sm")} {
    grid-template-columns: repeat(2, 1fr);
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    grid-template-columns: repeat(3, 1fr);
  }
`

function ProjectsGrid({
  projects,
  filters,
  locale,
  toProject,
  searchText,
}: {
  projects: AllProjects
  filters: ProjectFilters
  locale: string
  toProject: string
  searchText?: any
}) {
  return (
    <StyledGrid>
      {projects.map((el, index) => (
        <ProjectCard
          key={`${el.projectTitle} ${index.toString()}`}
          locale={locale}
          project={el}
          toProject={toProject}
          cities={filters.cities.options}
          countries={filters.countries.options}
          statuses={filters.statuses.options}
          subsidiaries={filters.subsidiaries.options}
          searchText={searchText}
          designation={filters.designations.options}
        />
      ))}
    </StyledGrid>
  )
}

export default ProjectsGrid
