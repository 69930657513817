"use client"
import styled from "@emotion/styled"
import Link from "next/link"

import { Desktop, Mobile, NewText } from "ui"
import ImageWithAlt from "ui/ImageWithAlt"
import { extractImage, extractProperty, iconClick } from "utils"

const StyledContainer = styled.div<{ $locale: string }>`
  width: 100%;
  padding: 16px;
  height: 88px;
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 8px;
  margin-top: 16px;
  border-radius: 16px;
  border: 1px solid var(--Grey, #6f6f6f);
  ${(props) => props.theme.breakpoints.up("md")} {
    justify-content: center;
    width: 248px;
    margin-top: 0;
    text-align: center;
  }
`

const StyledDiv = styled.div`
  /* flex: 1 1 0%; */
`

type Props = {
  data: any
  locale: string
}

export const ContactBox: React.FC<Props> = ({ data, locale }) => {
  const boxIcon = extractImage(data, "boxIcon")
  const text = extractProperty(data, "text")
  const link = extractProperty(data, "link")
  const isTelephone = link[0]?.url.includes("tel")

  return (
    <>
      <StyledDiv>
        <Desktop>
          <Link
            href={link[0]?.url || ""}
            target={link[0]?.target}
            style={{ textDecoration: "none", flex: 1 }}
            onClick={(e) => {
              isTelephone && e.preventDefault()
              iconClick(text, link[0]?.name, "יצירת קשר")
            }}
          >
            <StyledContainer $locale={locale}>
              <ImageWithAlt image={boxIcon} width={32} height={32} />

              <NewText
                desktopFontSize={20}
                desktopFontWeight="400"
                desktopLineHeight="normal"
                mobileFontSize={16}
                mobileFontWeight="400"
                mobileLineHeight={"normal"}
                component="p"
                color={"black"}
              >
                {text}
              </NewText>
            </StyledContainer>
          </Link>
        </Desktop>
      </StyledDiv>
      <Mobile>
        <Link
          href={link[0]?.url || ""}
          target={link[0]?.target}
          style={{ textDecoration: "none", flex: 1 }}
          onClick={() => iconClick(text, link[0]?.name, "יצירת קשר")}
        >
          <StyledContainer $locale={locale}>
            <ImageWithAlt image={boxIcon} width={40} height={40} />

            <NewText
              desktopFontSize={20}
              desktopFontWeight="400"
              desktopLineHeight="normal"
              mobileFontSize={20}
              mobileFontWeight="400"
              mobileLineHeight={"normal"}
              component="p"
              color={"black"}
            >
              {text}
            </NewText>
          </StyledContainer>
        </Link>
      </Mobile>
    </>
  )
}

export default ContactBox
