import styled from "@emotion/styled"
import { useLocale } from "next-intl"
import Link from "next/link"
import { Container } from "ui"
import { sharedColors } from "ui/theme/colors"

const StyledDiv = styled.div`
  padding-bottom: 32px;
  padding-top: 16px;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding-bottom: 60px;
  }
`

const StyledLink = styled(Link)`
  text-decoration: none;
  font-size: 16px;
  color: ${sharedColors.black};
  font-weight: 600;
  height: 48px;
  display: flex;
  border: 2px solid ${sharedColors.black};
  border-radius: 200px;
  padding: 8px 16px 8px 16px;
  width: fit-content;
  ${(props) => props.theme.breakpoints.up("md")} {
    font-size: 20px;
  }
`

type Props = {
  data: any
}

function HebrewButton({ data }: Props) {
  const text = data?.propertyValue?.value
  const locale = useLocale()

  return (
    <>
      {locale !== "he" ? (
        <Container>
          {text && (
            <StyledDiv>
              <StyledLink href={"/career"}>{text}</StyledLink>
            </StyledDiv>
          )}
        </Container>
      ) : null}
    </>
  )
}

export default HebrewButton
