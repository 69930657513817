import styled from "@emotion/styled"
import moment from "moment"
import { Desktop, LinkButton, Mobile, NewText } from "ui"
import { sharedColors as colors } from "ui/theme/colors"
import { InvestoreRelationsMessages } from "./InvestorsRelation"

type Props = {
  data: any
  messages: InvestoreRelationsMessages
}

const StyledContainer = styled.div``

const StyledDiv = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
  ${(props) => props.theme.breakpoints.up("md")} {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`

const StyledWrapper = styled.div``

const StyledLine = styled.div`
  height: 66px;
  width: 1px;
  background-color: #6f6f6f;
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 40px;
  ${(props) => props.theme.breakpoints.down("sm")} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
  }
`

const StyledBottomDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

function StockDto({ data, messages }: Props) {
  return (
    <StyledContainer>
      <StyledDiv>
        <TextWrapper>
          <StyledWrapper>
            <NewText
              component={"p"}
              desktopFontSize={18}
              desktopLineHeight={"35px"}
              desktopFontWeight={400}
              mobileFontSize={16}
              mobileLineHeight={"26px"}
              color={colors.black}
            >
              {messages.stockTicker}
            </NewText>
            <NewText
              component={"p"}
              desktopFontSize={32}
              desktopLineHeight={"36px"}
              desktopFontWeight={400}
              mobileFontSize={24}
              mobileLineHeight={"28px"}
              color={colors.black}
            >
              {data?.symbol}
            </NewText>
          </StyledWrapper>
          <Desktop>
            {" "}
            <StyledLine />
          </Desktop>
          <StyledWrapper>
            <NewText
              component={"p"}
              desktopFontSize={18}
              desktopLineHeight={"35px"}
              desktopFontWeight={400}
              mobileFontSize={16}
              mobileLineHeight={"26px"}
              color={colors.black}
            >
              {messages.price}
            </NewText>
            <NewText
              component={"p"}
              desktopFontSize={32}
              desktopLineHeight={"36px"}
              desktopFontWeight={400}
              mobileFontSize={24}
              mobileLineHeight={"28px"}
              color={colors.black}
            >
              {data?.securityLastRate}
            </NewText>
          </StyledWrapper>
          <Desktop>
            {" "}
            <StyledLine />
          </Desktop>
          <StyledWrapper>
            <NewText
              component={"p"}
              desktopFontSize={18}
              desktopLineHeight={"35px"}
              desktopFontWeight={400}
              mobileFontSize={16}
              mobileLineHeight={"26px"}
              color={colors.black}
            >
              {messages.change}
            </NewText>
            <NewText
              component={"p"}
              desktopFontSize={32}
              desktopLineHeight={"36px"}
              desktopFontWeight={400}
              mobileFontSize={24}
              mobileLineHeight={"28px"}
              color={data?.securityPercentageChange > 0 ? "#008015" : "red"}
              style={{ unicodeBidi: "plaintext", textAlign: "right" }}
            >
              {data?.securityPercentageChange}%
            </NewText>
          </StyledWrapper>
          <Desktop>
            {" "}
            <StyledLine />
          </Desktop>
          <StyledWrapper>
            <NewText
              component={"p"}
              desktopFontSize={18}
              desktopLineHeight={"35px"}
              desktopFontWeight={400}
              mobileFontSize={16}
              mobileLineHeight={"26px"}
              color={colors.black}
            >
              {messages.volume}
            </NewText>
            <NewText
              component={"p"}
              desktopFontSize={32}
              desktopLineHeight={"36px"}
              desktopFontWeight={400}
              mobileFontSize={24}
              mobileLineHeight={"28px"}
              color={colors.black}
            >
              {data?.securityDailyAggVolume}
            </NewText>
          </StyledWrapper>
        </TextWrapper>
        <Desktop>
          <LinkButton
            link={[
              { url: "/investors-relations/stocks", name: messages.tostock },
            ]}
            mainTitle="מניית קבוצת אשטרום"
          />
        </Desktop>
      </StyledDiv>
      <StyledBottomDiv>
        <NewText
          component={"p"}
          desktopFontSize={18}
          desktopLineHeight={"24px"}
          desktopFontWeight={400}
          mobileFontSize={14}
          mobileLineHeight={"18px"}
          color={colors.black}
        >
          * {messages.lastUpdate}:{" "}
          {moment(data?.lastTransaction).format("DD/MM/YYYY HH:mm")}
        </NewText>
        <Mobile>
          <LinkButton
            link={[{ url: "/investors-relations/stocks", name: "לנתוני מניה" }]}
            mainTitle="מניית קבוצת אשטרום"
          />
        </Mobile>
      </StyledBottomDiv>
    </StyledContainer>
  )
}

export default StockDto
