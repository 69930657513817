"use client"
import styled from "@emotion/styled"
import Accordion from "@mui/material/Accordion"
import AccordionDetails from "@mui/material/AccordionDetails"
import AccordionSummary from "@mui/material/AccordionSummary"
import Tab from "@mui/material/Tab"
import Tabs from "@mui/material/Tabs"
import { styled as miuiStyled } from "@mui/material/styles"
import { useState } from "react"
import "swiper/css"
import { AccordionCloseIcon, AccordionExpandIcon, Container, NewText } from "ui"
import { extractProperty, faqClickClose, faqClickOpen, filterClick } from "utils"

const StyledSlideContainer = styled.div`
  background-color: white;
  width: 100%;
  ${(props) => props.theme.breakpoints.down("sm")} {
    margin-top: 32px;
  }
  .MuiTab-root {
    font-size: 18px;
    ${(props) => props.theme.breakpoints.up("md")} {
      font-size: 20px;
    }
  }
  .Mui-selected {
    border-bottom: 2px solid #2768a3;
  }
  .MuiAccordionSummary-root {
    background: linear-gradient(90deg, #1d4973 0%, #317bbf 60.44%, #3684cc 100%);
    height: 84px;
    ${(props) => props.theme.breakpoints.up("md")} {
      height: 88px;
    }
    border-radius: 8px;
    &::before {
      border-bottom: none;
    }
  }

  .MuiCollapse-root {
    border: 1px solid #2768a3;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    ${(props) => props.theme.breakpoints.down("sm")} {
      /* background: linear-gradient(
        90deg,
        #3684cc -19.36%,
        #317bbf 35.51%,
        #1d4973 119.36%
      ); */
      background: white;
    }
    &:hover {
      background: linear-gradient(90deg, #3684cc -19.36%, #317bbf 35.51%, #1d4973 119.36%);
      cursor: pointer;
      p,
      li,
      h4 {
        color: white;
      }
    }
  }
`

const StyledAccordionSummary = styled(AccordionSummary)`
  &.MuiButtonBase-root {
    &.Mui-expanded {
      border-radius: 0px;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      .MuiAccordionSummary-expandIconWrapper {
        transform: rotate(0deg);
      }
    }
  }
`

const StyledContentContainer = styled.div`
  padding: 0;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding: 0px;
  }
  p {
    margin: 0px;
  }
`
const StyledTabstContainer = styled.div`
  .MuiTabs-scroller {
    overflow: auto !important;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`

const StyledAccordionDetails = miuiStyled(AccordionDetails)(() => ({
  padding: 10,
}))

function CustomTabPanel(props: any) {
  const { children, value, index, ...rest } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ margin: 0, padding: 0, width: "100%" }}
      {...rest}
    >
      {value === index && children}
    </div>
  )
}

type Props = {
  data: any
}

export const FaqMain: React.FC<Props> = ({ data }) => {
  const content = extractProperty(data, "fAQMain").map((item: any) => ({
    tabName: extractProperty(item, "tabName"),
    faqs: extractProperty(item, "faqs").map((item: any) => ({
      accordionTitle: extractProperty(item, "accordionTitle"),
      accordionContent: extractProperty(item, "accordionContent"),
    })),
  }))

  const [value, setValue] = useState<number>(0)
  const [expanded, setExpanded] = useState<string | false>(false)

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setExpanded(false)
    filterClick(content[newValue].tabName, "תשובות שאלות ברכיב פילטר")
    setValue(newValue)
  }

  return (
    <Container>
      <StyledSlideContainer>
        <StyledTabstContainer>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            {content.map((tab: any, index: number) => (
              <Tab key={index.toString()} label={tab.tabName} />
            ))}
          </Tabs>
        </StyledTabstContainer>
        <StyledContentContainer>
          {content.map((tab: any, index: number) => (
            <CustomTabPanel key={index.toString()} value={value} index={index}>
              {tab.faqs.map((i: any, k: number) => (
                <Accordion
                  key={k.toString()}
                  style={{
                    padding: 0,
                    margin: 0,
                    marginBlockStart: 24,
                    boxShadow: "0px 4px 12px 0px rgba(0, 0, 0, 0.08)",
                    border: "none",
                    borderRadius: 8,
                  }}
                  onChange={() => {
                    setExpanded((prev) => {
                      if (prev === `acc-${k}`) {
                        faqClickClose(i.accordionTitle, "תשובות שאלות ברכיב פילטר", "close")
                        return false
                      } else {
                        faqClickOpen(i.accordionTitle, "תשובות שאלות ברכיב פילטר", "open")
                        return `acc-${k}`
                      }
                    })
                  }}
                  sx={{
                    "&:before": {
                      display: "none",
                    },
                  }}
                >
                  <StyledAccordionSummary
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    expandIcon={
                      expanded === `acc-${k}` ? <AccordionCloseIcon /> : <AccordionExpandIcon />
                    }
                    style={{
                      border: "none",
                      outline: "none",
                    }}
                    sx={{
                      "&:before":
                        expanded === `acc-${k}`
                          ? {
                              // eslint-disable-next-line quotes
                              content: '" "',
                              textDecoration: "none",
                              position: "absolute",
                              width: "calc(100% - 32px)",
                              height: "100%",
                              left: "16px",
                              borderBottom: "solid 1px #F7F7F7",
                            }
                          : {
                              display: "none",
                            },
                    }}
                  >
                    <NewText
                      desktopFontSize={20}
                      desktopFontWeight="600"
                      desktopLineHeight="35px"
                      mobileFontSize={18}
                      mobileFontWeight="600"
                      mobileLineHeight={"24px"}
                      component="p"
                      color={"white"}
                    >
                      {i.accordionTitle}
                    </NewText>
                  </StyledAccordionSummary>
                  <StyledAccordionDetails>
                    <NewText
                      desktopFontSize={20}
                      desktopFontWeight="400"
                      desktopLineHeight="26px"
                      mobileFontSize={16}
                      mobileFontWeight="400"
                      mobileLineHeight={"20px"}
                      component="div"
                      color={"black"}
                      dangerouslySetInnerHTML={{ __html: i.accordionContent }}
                      maxWidth={"800px"}
                      marginTop={"16px"}
                      marginBottom={"16px"}
                    />
                  </StyledAccordionDetails>
                </Accordion>
              ))}
            </CustomTabPanel>
          ))}
        </StyledContentContainer>
      </StyledSlideContainer>
    </Container>
  )
}

export default FaqMain
