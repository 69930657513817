import styled from "@emotion/styled"
import { useLocale } from "next-intl"
import { Container, Desktop, Mobile, NewText } from "ui"
import ImageWithAlt from "ui/ImageWithAlt"
import { sharedColors } from "ui/theme/colors"
import { extractImage, extractProperty } from "utils"

const StyledDiv = styled.div`
  padding-top: 20px;
  padding-bottom: 32px;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding-top: 24px;
    padding-bottom: 40px;
  }
`

const StyledImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 340px;
  border-radius: 16px;
  overflow: hidden;
  ${(props) => props.theme.breakpoints.up("md")} {
    height: 240px;
  }
`

const StyledLayer = styled.div<{ $locale: string }>`
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 30%,
    rgba(0, 0, 0, 0.64) 100%
  );
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;

  ${(props) => props.theme.breakpoints.up("md")} {
    background: ${(p) =>
      p.$locale === "he"
        ? "linear-gradient(270deg,rgba(0, 0, 0, 0.64) 0%,rgba(0, 0, 0, 0) 70% )"
        : "linear-gradient( 90deg,rgba(0, 0, 0, 0.64) 0%,rgba(0, 0, 0, 0) 70%)"};
  }
`

const StyledTextWrapp = styled.div`
  position: absolute;
  z-index: 2;
  bottom: 16px;
  padding-left: 16px;
  padding-right: 16px;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding-left: 0px;
    padding-right: 0px;
    bottom: unset;
    left: 32px;
    top: 50%;
    transform: translate(0, -50%);
    max-width: 500px;
  }
`

type Props = {
  data: any
}
const TopBannerEligible = ({ data }: Props) => {
  const desktopImage = extractImage(data, "desktopImage")
  const mobileImage = extractImage(data, "mobileImage")
  const pageTitle = extractProperty(data, "pageTitle")

  const locale = useLocale()
  return (
    <Container>
      <StyledDiv>
        <StyledImageWrapper>
          <StyledLayer $locale={locale} />
          <Desktop>
            <ImageWithAlt
              image={desktopImage}
              fill
              style={{ objectFit: "cover" }}
            />
          </Desktop>
          <Mobile>
            <ImageWithAlt
              image={mobileImage}
              fill
              style={{ objectFit: "cover" }}
            />
          </Mobile>
          <StyledTextWrapp>
            <NewText
              component={"h1"}
              desktopFontSize={40}
              desktopLineHeight={"44px"}
              desktopFontWeight={400}
              mobileFontSize={32}
              mobileLineHeight={"36px"}
              color={sharedColors.white}
            >
              {pageTitle}
            </NewText>
          </StyledTextWrapp>
        </StyledImageWrapper>
      </StyledDiv>
    </Container>
  )
}

export default TopBannerEligible
