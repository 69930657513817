import styled from "@emotion/styled"
import { yupResolver } from "@hookform/resolvers/yup"
import { Button, Checkbox, TextField } from "@mui/material"
import { postWebBmbyLead, ProjectStatus } from "api"
import { useLocale, useTranslations } from "next-intl"
import { LegacyRef, useRef, useState } from "react"
import ReCAPTCHA from "react-google-recaptcha"
import { Controller, useForm } from "react-hook-form"
import {
  CheckboxIcon,
  CheckedCheckboxIcon,
  Container,
  FormSubmitFailIcon,
  FormSubmitSuccessIcon,
  NewText,
} from "ui"
import ImageWithAlt from "ui/ImageWithAlt"
import { sharedColors } from "ui/theme/colors"
import { extractImage, extractProperty, submitClick, submitError, submitSuccess } from "utils"
import { regex } from "utils/Form/FormSchema"
import * as yup from "yup"
import { ctaMessage } from "../Contact/ContactForm"
import CustomHelperText from "../Contact/CustomHelperText"

// eslint-disable-next-line prefer-destructuring
const RECAPTCHA_KEY = process.env.RECAPTCHA_KEY
if (!RECAPTCHA_KEY) {
  throw new Error("Please provide the RECAPTCHA_KEY environment variable.")
}

const StyledContainer = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledDiv = styled.div`
  width: 100%;
  padding-top: 32px;
  padding-bottom: 32px;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding-top: 40px;
    width: 80%;
    padding-bottom: 60px;
  }
`

const StyledTextWrapp = styled.div`
  display: flex;
  flex-direction: row;
  gap: 21px;
  align-items: center;
  justify-content: flex-start;

  ${(props) => props.theme.breakpoints.up("md")} {
    justify-content: center;
  }
`

const StyledFormDiv = styled.div`
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const FormContainer = styled.form`
  margin-top: 16px;

  ${(props) => props.theme.breakpoints.up("md")} {
  }
`

const FormTopContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${(props) => props.theme.breakpoints.up("md")} {
    flex-direction: row;
    gap: 16px;
  }
  .MuiFormHelperText-root {
    bottom: -25px;
    line-height: 1;
  }
`

const TextAreaContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`

const InputLengthContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 4px;
`

const StyledCheckbox = styled(Checkbox, {
  shouldForwardProp: (prop) => prop !== "status",
})<{ status: boolean }>`
  padding: 1px 10px 0px 0;
  ${(props) =>
    !props.status
      ? `  rect {
    stroke: red;
  }`
      : ""}
`

const StyledCheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
`

const CheckBoxesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
`

const StyledButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 40px;
`

const StyledButton = styled(Button)`
  min-width: 153px;
  border: 2px solid ${sharedColors.black};
  border-radius: 200px;
  text-decoration: none;
`

type Props = {
  data: any
  companyId?: number
}

const messagesInvalid = {
  en: {
    firstName: "Letters must be typed in Hebrew or English only and without special symbols",
    phoneNumber: "The phone number entered is incorrect",
    email: "The email address entered is invalid",
    requiredName: "Please enter a valid full name",
    requiredPhoneNumber: "Please enter a valid phone",
    requiredEmail: "Please enter a valid email address",
  },
  he: {
    firstName: "יש להקליד אותיות בעברית או באנגלית בלבד וללא סימנים מיוחדים",
    phoneNumber: "מספר הטלפון שהוזן אינו תקין",
    email: "כתובת האימייל שהוזנה אינה תקינה",
    requiredName: "נא להזין שם מלא תקין",
    requiredPhoneNumber: "נא הזן מספר טלפון תקין",
    requiredEmail: "נא הזן כתובת דוא”ל תקינה",
  },
}

const generateSchema = (locale: string) => {
  const msg = messagesInvalid[locale as "en" | "he"]

  return yup.object({
    fullName: yup
      .string()
      .matches(regex.singleName, msg.requiredName)
      .min(5, msg.requiredName)
      .max(50, msg.requiredName)
      .required(msg.requiredName),
    email: yup.string().matches(regex.email, msg.requiredEmail).required(msg.requiredEmail),
    phoneNumber: yup
      .string()
      .matches(regex.phoneNumber, msg.requiredPhoneNumber)
      .length(10, msg.requiredPhoneNumber)
      .required(msg.requiredPhoneNumber),
  })
}

const EligibilityForm = ({ data, companyId }: Props) => {
  const blockTitle = extractProperty(data, "blockTitle")
  const titleIcon = extractImage(data, "titleIcon")
  const logo = extractImage(data, "logo")
  const t = useTranslations("ProjectsPage")
  const reCaptchaRef = useRef<ReCAPTCHA>()
  const locale = useLocale()
  const schema = generateSchema(locale)
  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitted, isValid },
    watch,
    reset,
  } = useForm({
    defaultValues: {
      fullName: "",
      phoneNumber: "",
      email: "",
      message: "",
    },
    resolver: yupResolver(schema),
  })
  const messageValue = watch("message")

  const [sendSucceded, setSendSucceded] = useState<boolean | null>(null)
  const [checkedMarketing, setCheckedMarketing] = useState<boolean>(false)
  const [checkedCommercial, setCheckedCommercial] = useState<boolean>(false)

  const onSubmit = async (data: any) => {
    reCaptchaRef?.current?.reset()
    const recaptchaResponse = (await reCaptchaRef?.current?.executeAsync()) as string

    try {
      const senddata = {
        projectId: 0,
        name: data.fullName,
        email: data.email,
        phone: data.phoneNumber,
        comments: data.message || "",
        gRecaptchaResponse: recaptchaResponse,
        projectStatus: "Entitled" as ProjectStatus,
        allowedMarketing: checkedMarketing,
        allowedSmsAndEmail: checkedCommercial,
        companyId: companyId || -1,
      }
      await postWebBmbyLead({ ...senddata })
      submitSuccess("שליחה", "טופס צור קשר", true)

      setSendSucceded(true)
      reset()
    } catch (error) {
      setSendSucceded(false)
      submitError("שליחה", t("forYou"), error as any)
    }
  }
  const handleSendError = () => {
    if (!isValid) {
      if (Object.keys(errors).length > 0) {
        submitError(
          "שליחה",
          "טופס צור קשר ",
          errors?.fullName?.message || errors?.email?.message || errors?.phoneNumber?.message,
        )
      } else {
        submitError("שליחה", "טופס צור קשר ", "subject error")
      }
    }
  }

  return (
    <StyledContainer>
      <StyledDiv>
        <StyledTextWrapp>
          <ImageWithAlt image={titleIcon} width={50} height={50} style={{ objectFit: "contain" }} />
          <NewText
            component={"p"}
            desktopFontSize={32}
            desktopLineHeight={"36px"}
            mobileFontSize={24}
            mobileLineHeight={"28px"}
            desktopFontWeight={400}
            color={sharedColors.black}
          >
            {blockTitle}
          </NewText>
        </StyledTextWrapp>
        <StyledFormDiv>
          {sendSucceded !== null ? (
            <>
              {sendSucceded ? (
                <FormSubmitSuccessIcon fill={sharedColors.oldBrandBlue} />
              ) : (
                <FormSubmitFailIcon />
              )}

              <NewText
                desktopFontSize={32}
                desktopFontWeight="400"
                desktopLineHeight="36px"
                mobileFontSize={24}
                mobileFontWeight="400"
                mobileLineHeight={"28px"}
                component="h1"
                color={"black"}
                paddingTop={"8px"}
              >
                {sendSucceded ? t("contactSucessTitle") : "פנייתך לא נשלחה "}
              </NewText>
              <NewText
                desktopFontSize={24}
                desktopFontWeight="400"
                desktopLineHeight="35px"
                mobileFontSize={18}
                mobileFontWeight="400"
                mobileLineHeight={"24px"}
                component="h1"
                color={"black"}
                textAlign="center"
              >
                {sendSucceded
                  ? t("contactSucessText")
                  : "לורם איפסום דולר לורם איפסום דולר לורם איפסום דולר לורם איפסום"}
              </NewText>
              {!sendSucceded && (
                <StyledButton onClick={() => setSendSucceded(null)}>
                  {locale === "he" ? " ניסיון נוסף" : "Try Again"}
                </StyledButton>
              )}
            </>
          ) : (
            <>
              <NewText
                desktopFontSize={20}
                desktopFontWeight="400"
                desktopLineHeight="26px"
                mobileFontSize={16}
                mobileFontWeight="400"
                mobileLineHeight={"24px"}
                component="h1"
                color={"black"}
              >
                {t("required")}
              </NewText>
              <FormContainer onSubmit={handleSubmit(onSubmit)}>
                <FormTopContainer>
                  <Controller
                    name="fullName"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label={t("fullName")}
                        type="text"
                        variant="filled"
                        error={!!errors.fullName}
                        helperText={
                          <CustomHelperText
                            render={isSubmitted}
                            error={!!errors.fullName}
                            text={errors.fullName?.message}
                          />
                        }
                        sx={{
                          marginBlockEnd: "37px",
                        }}
                        fullWidth
                      />
                    )}
                  />
                  <Controller
                    name="phoneNumber"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label={t("telephone")}
                        type="text"
                        variant="filled"
                        error={!!errors.phoneNumber}
                        helperText={
                          <CustomHelperText
                            render={isSubmitted}
                            error={!!errors.phoneNumber}
                            text={errors.phoneNumber?.message}
                          />
                        }
                        fullWidth
                        sx={{
                          marginBlockEnd: "37px",
                        }}
                      />
                    )}
                  />
                  <Controller
                    name="email"
                    control={control}
                    render={({ field }) => {
                      return (
                        <TextField
                          {...field}
                          label={t("email")}
                          type="text"
                          variant="filled"
                          error={!!errors.email}
                          helperText={
                            <CustomHelperText
                              render={isSubmitted}
                              error={!!errors.email}
                              text={errors.email?.message}
                            />
                          }
                          fullWidth
                          sx={{
                            marginBlockEnd: "37px",
                          }}
                        />
                      )
                    }}
                  />
                </FormTopContainer>
                <TextAreaContainer>
                  <Controller
                    name="message"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label={t("messageOptional")}
                        type="text"
                        variant="filled"
                        error={false}
                        helperText={
                          <CustomHelperText
                            render={isSubmitted}
                            error={false}
                            text={errors.message?.message}
                          />
                        }
                        fullWidth
                        multiline
                        rows={6}
                        inputProps={{
                          maxLength: 500,
                          style: {
                            resize: "vertical",
                          },
                        }}
                        sx={{
                          "& .MuiFormHelperText-root": {
                            left: "40px",
                            bottom: "-22px",
                          },
                          "& .MuiInputLabel-root": {
                            top: "-5px",
                          },
                        }}
                      />
                    )}
                  />
                  <InputLengthContainer>
                    <NewText
                      desktopFontSize={12}
                      desktopFontWeight="400"
                      desktopLineHeight="17.28px"
                      mobileFontSize={12}
                      mobileFontWeight="400"
                      mobileLineHeight={"17.28px"}
                      component="h1"
                      color={"black"}
                    >
                      {messageValue.length}/500
                    </NewText>
                  </InputLengthContainer>
                </TextAreaContainer>

                <CheckBoxesContainer>
                  <StyledCheckboxContainer>
                    <StyledCheckbox
                      icon={<CheckboxIcon />}
                      checkedIcon={<CheckedCheckboxIcon />}
                      sx={{
                        ":hover": {
                          backgroundColor: "transparent",
                        },
                      }}
                      status={true}
                      value={checkedMarketing}
                      onChange={() => setCheckedMarketing((el) => !el)}
                    />

                    <div>
                      <NewText
                        desktopFontSize={18}
                        desktopFontWeight="400"
                        desktopLineHeight="25.92px"
                        mobileFontSize={14}
                        mobileFontWeight="400"
                        mobileLineHeight={"20px"}
                        component="p"
                        color={"black"}
                        maxWidth={"750px"}
                      >
                        {t("marketingMessage")}
                      </NewText>
                    </div>
                  </StyledCheckboxContainer>
                  <StyledCheckboxContainer>
                    <StyledCheckbox
                      icon={<CheckboxIcon />}
                      checkedIcon={<CheckedCheckboxIcon />}
                      sx={{
                        ":hover": {
                          backgroundColor: "transparent",
                        },
                      }}
                      status={true}
                      value={checkedCommercial}
                      onChange={() => setCheckedCommercial((el) => !el)}
                    />

                    <div>
                      <NewText
                        desktopFontSize={18}
                        desktopFontWeight="400"
                        desktopLineHeight="25.92px"
                        mobileFontSize={14}
                        mobileFontWeight="400"
                        mobileLineHeight={"20px"}
                        component="p"
                        color={"black"}
                        maxWidth={"750px"}
                        dangerouslySetInnerHTML={{
                          __html: t("commercialMessage"),
                        }}
                      ></NewText>
                    </div>
                  </StyledCheckboxContainer>
                </CheckBoxesContainer>
                <div style={{ paddingTop: "16px" }}>
                  <NewText
                    desktopFontSize={18}
                    desktopFontWeight="400"
                    desktopLineHeight="25.92px"
                    mobileFontSize={14}
                    mobileFontWeight="400"
                    mobileLineHeight={"20px"}
                    component="p"
                    color={"black"}
                    maxWidth={"750px"}
                    dangerouslySetInnerHTML={{
                      __html: ctaMessage(t("ctaMessage") || ""),
                    }}
                  ></NewText>
                </div>
                <StyledButtonWrapper>
                  <ImageWithAlt
                    image={logo}
                    width={72}
                    height={72}
                    style={{ objectFit: "contain" }}
                  />
                  <StyledButton
                    type="submit"
                    onClick={() => {
                      submitClick(" שליחה", "טופס צור קשר")
                      handleSendError()
                    }}
                  >
                    <NewText
                      component={"p"}
                      desktopFontSize={20}
                      desktopFontWeight={600}
                      color={sharedColors.black}
                    >
                      {t("send")}
                    </NewText>
                  </StyledButton>
                </StyledButtonWrapper>
                {RECAPTCHA_KEY && (
                  <ReCAPTCHA
                    ref={reCaptchaRef as LegacyRef<ReCAPTCHA> | undefined}
                    sitekey={RECAPTCHA_KEY}
                    size="invisible"
                    hl="iw"
                    tabIndex={-1}
                  />
                )}
              </FormContainer>
            </>
          )}
        </StyledFormDiv>
      </StyledDiv>
    </StyledContainer>
  )
}

export default EligibilityForm
