"use client"
import styled from "@emotion/styled"
import { IconButton } from "@mui/material"
import { useLocale } from "next-intl"
import Image from "next/image"
import React, { createRef, useRef, useState } from "react"
import { Autoplay, Pagination } from "swiper"
import "swiper/css"
import { Swiper, SwiperSlide } from "swiper/react"
import {
  LeftArrow,
  RightArrow,
  StartIcon,
  StopSign,
  TransitionUp,
  transitionTime,
  useIsDesktop,
} from "ui"
import { sharedColors } from "ui/theme/colors"
import { getUmbracoMediaUrl } from "utils"
import { extractProperty } from "utils/extractProperty"

const StyledContainer = styled.div`
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  /* max-width: 1040px; */
  ${(props) => props.theme.breakpoints.up("md")} {
    border-radius: 16px;
  }
`
const StyledPaginatorContainer = styled.div`
  position: absolute;
  background-color: #fff;
  bottom: 11px;
  left: 8px;
  z-index: 10;
  padding: 2px 8px;
  border-radius: 200px;
  height: 26px;
  display: flex;

  ${(props) => props.theme.breakpoints.up("md")} {
    top: unset;
    bottom: 24px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 3px 8px;
    border-radius: 200px;
    background-color: white;
  }
`

const StyledBulletsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 0 4px;
`

const StyledMobileImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 260px;
  ${(props) => props.theme.breakpoints.up("md")} {
    display: none;
  }
`

const StyledDesktopImageContainer = styled.div`
  ${(props) => props.theme.breakpoints.down("md")} {
    display: none;
  }
  position: relative;
  width: 460px;
  height: 480px;
`
const StyledPlayButton = styled.div`
  display: flex;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  gap: 4.923px;
  border-radius: 123.077px;
  background-color: ${sharedColors.white};
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.16);
  position: absolute;
  cursor: pointer;
  z-index: 30;
  right: 8px;
  bottom: 8px;
  ${(props) => props.theme.breakpoints.up("md")} {
    right: 50%;
    top: 50%;
    transform: translate(50%, -50%);
    width: 60px;
    height: 60px;
    svg {
      width: 30px;
      height: 30px;
    }
  }
`
const StyledBullet = styled.div<{ active: boolean }>`
  width: 14px;
  height: 14px;
  border-radius: 50%;
  margin: 0 8px;
  cursor: pointer;
  border: ${(props) => (props.active ? "2.33px solid #2768A3" : "2.33px solid black")};
  background-color: ${(props) => (props.active ? "#2768A3" : "none")};
`

const StyledVideo = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  ${(props) => props.theme.breakpoints.up("md")} {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`

type Slide = {
  desktopMedia: string
  mobileMedia: string
}

type Props = {
  data: any
  inView: boolean
}
function HistorySlider({ data, inView }: Props) {
  const videoExtensions = /\.(mp4|mov|avi|wmv|flv|mkv)$/i
  const isDesktop = useIsDesktop()
  const locale = useLocale()
  const [swiperRef, setSwiperRef] = useState<any>(null)
  const [activeIndex, setActiveIndex] = useState<number>(0)
  const slides: Slide[] = extractProperty(data, "slideElements").map((i: any) => {
    const desktopMedia = extractProperty(i, "imageOrVideoSelsctor")
    return {
      desktopMedia,
      mobileMedia: extractProperty(i, "imageOrVideoSelectorMobaile") || desktopMedia,
    }
  })
  const videoRef = useRef(slides.map(() => createRef<HTMLVideoElement>()))

  const videoMobileRef = useRef(slides.map(() => createRef<HTMLVideoElement>()))
  const [videosPaused, setVideosPaused] = useState<React.RefObject<HTMLVideoElement>[]>([])

  const handlePlayPause = (key: number) => {
    if (isDesktop) {
      const video = videoRef.current[key].current
      if (video) {
        if (videosPaused.includes(videoRef.current[key])) {
          video.play()
          setVideosPaused(videosPaused.filter((ref) => ref !== videoRef.current[key]))
        } else {
          video.pause()
          setVideosPaused([...videosPaused, videoRef.current[key]])
        }
      } else {
        console.error(`No video found`)
      }
    } else {
      const video = videoMobileRef.current[key].current
      if (video) {
        if (videosPaused.includes(videoMobileRef.current[key])) {
          video.play()
          setVideosPaused(videosPaused.filter((ref) => ref !== videoMobileRef.current[key]))
        } else {
          video.pause()
          setVideosPaused([...videosPaused, videoMobileRef.current[key]])
        }
      } else {
        console.error(`No video found`)
      }
    }
  }

  return (
    <TransitionUp $inView={inView}>
      <StyledContainer>
        <Swiper
          onSwiper={setSwiperRef}
          onSlideChange={(swiper: any) => {
            setActiveIndex(swiper.activeIndex)
          }}
          style={{
            padding: 0,
            borderRadius: isDesktop ? 16 : 0,
            position: "relative",
          }}
          modules={[Autoplay, Pagination]}
          speed={transitionTime * 1000}
          autoplay={false}
          pagination={{
            el: ".swiper-pagination",
            dynamicBullets: true,
            dynamicMainBullets: 5,
          }}
        >
          {slides.map((item: { desktopMedia: string; mobileMedia: string }, key: number) => {
            return (
              <SwiperSlide key={key.toString()}>
                <StyledDesktopImageContainer>
                  {!videoExtensions.test(item?.desktopMedia) ? (
                    <Image
                      src={getUmbracoMediaUrl(item?.desktopMedia)}
                      alt="product-image"
                      fill
                      style={{
                        objectFit: "cover",
                      }}
                    />
                  ) : (
                    <>
                      <StyledVideo
                        autoPlay
                        loop
                        muted={true}
                        ref={videoRef?.current?.[key]}
                        playsInline
                      >
                        <source src={getUmbracoMediaUrl(item?.desktopMedia)} type="video/mp4" />
                      </StyledVideo>
                      <StyledPlayButton
                        onClick={() => handlePlayPause(key)}
                        aria-label={
                          !videosPaused.includes(videoRef.current[key])
                            ? "stop-playing-button"
                            : "start-playing-button"
                        }
                      >
                        {!videosPaused.includes(videoRef.current[key]) ? (
                          <StopSign />
                        ) : (
                          <StartIcon />
                        )}
                      </StyledPlayButton>
                    </>
                  )}
                </StyledDesktopImageContainer>
                <StyledMobileImageContainer>
                  {!videoExtensions.test(item?.mobileMedia) ? (
                    <Image
                      src={getUmbracoMediaUrl(item?.mobileMedia)}
                      alt="product-image"
                      fill
                      style={{
                        objectFit: "cover",
                      }}
                    />
                  ) : (
                    <>
                      <StyledVideo
                        autoPlay
                        loop
                        muted={true}
                        ref={videoMobileRef?.current?.[key]}
                        playsInline
                      >
                        <source src={getUmbracoMediaUrl(item?.mobileMedia)} type="video/mp4" />
                      </StyledVideo>
                      <StyledPlayButton
                        onClick={() => handlePlayPause(key)}
                        aria-label={
                          !videosPaused.includes(videoMobileRef.current[key])
                            ? "stop-playing-button"
                            : "start-playing-button"
                        }
                      >
                        {!videosPaused.includes(videoMobileRef.current[key]) ? (
                          <StopSign />
                        ) : (
                          <StartIcon />
                        )}
                      </StyledPlayButton>
                    </>
                  )}
                </StyledMobileImageContainer>
              </SwiperSlide>
            )
          })}
          {slides.length > 1 && (
            <StyledPaginatorContainer>
              <IconButton
                onClick={() => {
                  swiperRef.slidePrev()
                }}
                style={{
                  width: 22,
                  height: 22,
                  padding: 0,
                }}
              >
                {locale === "en" ? <LeftArrow /> : <RightArrow />}
              </IconButton>
              <StyledBulletsContainer>
                {slides.map((_: any, index: number) => (
                  <StyledBullet
                    key={index.toString()}
                    onClick={() => {
                      swiperRef.slideTo(index)
                    }}
                    active={index === activeIndex}
                  />
                ))}
              </StyledBulletsContainer>
              <IconButton
                onClick={() => {
                  swiperRef.slideNext()
                }}
                style={{
                  width: 22,
                  height: 22,
                  padding: 0,
                }}
              >
                {locale === "en" ? <RightArrow /> : <LeftArrow />}
              </IconButton>
            </StyledPaginatorContainer>
          )}
        </Swiper>
      </StyledContainer>
    </TransitionUp>
  )
}

export default HistorySlider
