"use client"

import { useState } from "react"
import { BlockRenderer, Container, CustomBreadcrumbs } from "ui"
import { Apartment, findProperty, getBreadCrumbs } from "utils"
import { GetAllAp } from "utils/priorityApartments"
import { ProjectsMessages } from "../Projects/Projects"
import Apartments from "./Apartments"
import ApartmentsBanner from "./ApartmentsBanner"
import CitiesFilter from "./CitiesFilter"

type Props = {
  data: any
  locale: string
  apartForPage: Apartment[]
  combinedData: any
  messages: ProjectsMessages
  allApartments: GetAllAp
}

const components = {
  apartmentsBanner: ApartmentsBanner,
}
function ApartmentsRent({
  data,
  locale,
  apartForPage,
  combinedData,
  messages,
  allApartments,
}: Props) {
  const { selfObject, parents } = getBreadCrumbs(data, locale)
  const blocks = findProperty(data.page, "blocks")?.blocks || []
  const hasBanner = blocks?.some(
    (block: any) => block.blockType === "topBanner" || block.blockType === "HeaderWithHeroAndText",
  )
  const [selectedFilters, setSelectedFilters] = useState<Array<string>>(["all"])
  const { apartmentsArr, allApChar, allBuChar } = allApartments
  const rawCities = apartmentsArr.map((el) => el.city).filter((el) => el) as string[]
  const citiesSet = new Set(rawCities)
  const citiesArr = Array.from(citiesSet).map((el) => ({
    name: el,
    value: el,
  })) as { name: string; value: string }[]

  return (
    <>
      <Container>
        <CustomBreadcrumbs current={selfObject} parents={parents} />
      </Container>
      {blocks?.map((block: any, index: number) => {
        let additionalProps = {}
        if (block.blockType === "contentText") {
          additionalProps = {
            hasBanner,
          }
        }
        return (
          <BlockRenderer
            key={index}
            blockType={block.blockType}
            blockData={block}
            components={components}
            props={additionalProps}
          />
        )
      })}
      <CitiesFilter
        availibleCities={citiesArr}
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
      />
      <Apartments
        allApChar={allApChar}
        allBuChar={allBuChar}
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        allApartments={apartmentsArr}
        apartForPage={apartForPage}
        total={combinedData.length}
        pageData={data}
        messages={messages}
        locale={locale}
      />
    </>
  )
}

export default ApartmentsRent
