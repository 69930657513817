import styled from "@emotion/styled"
import { Tab, Tabs } from "@mui/material"
import { useState } from "react"
import { Container, NewText } from "ui"
import TabPanel from "ui/TabPanel"
import { sharedColors as colors } from "ui/theme/colors"
import { extractProperty } from "utils"
import Bonds from "./Bonds"
import { InvestoreRelationsMessages } from "./InvestorsRelation"
import Stock from "./Stocks"

const StyledDiv = styled.div`
  padding-top: 32px;
  padding-bottom: 32px;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding-top: 60px;
    padding-bottom: 80px;
  }
`
const StyledTabs = styled(Tabs)`
  margin-top: 24px;
  width: 216px;
  border-radius: 200px;
  padding: 8px;
  background-color: #f7f7f7;
  ${(props) => props.theme.breakpoints.up("md")} {
    width: 246px;
  }

  .MuiTab-root {
    color: #6f6f6f;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    padding-bottom: 0px;
    border-bottom: 6px solid transparent;

    ${(props) => props.theme.breakpoints.up("md")} {
      font-size: 20px;
    }
  }

  .Mui-selected {
    background-color: ${colors.white};
    color: ${colors.black};
    border-radius: 200px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;

    ${(props) => props.theme.breakpoints.up("md")} {
      font-size: 20px;
    }
  }
`

type Props = {
  data: any
  bondsData: any
  stockGraph: any
  messages: InvestoreRelationsMessages
  locale: string
  page?: string
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  }
}

function ShareBlock({
  data,
  bondsData,
  stockGraph,
  messages,
  page,
  locale,
}: Props) {
  const title = extractProperty(data, "title")

  const [selectedValue, setSelectedValue] = useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedValue(newValue)
  }

  if (!stockGraph || !bondsData) return null

  return (
    <Container>
      <StyledDiv>
        <NewText
          component={"h2"}
          desktopFontSize={40}
          desktopLineHeight={"44px"}
          desktopFontWeight={400}
          mobileFontSize={32}
          mobileLineHeight={"36px"}
          color={colors.black}
        >
          {title}
        </NewText>
        {page === "corporate" ? (
          <>
            <StyledTabs
              value={selectedValue}
              onChange={handleChange}
              aria-label="person tabs"
            >
              <Tab label={messages.stockInfo} {...a11yProps(0)} />
              <Tab label={messages.bonds} {...a11yProps(1)} />
            </StyledTabs>
            <TabPanel value={selectedValue} index={0}>
              {stockGraph && <Stock data={stockGraph} messages={messages} />}
            </TabPanel>
            <TabPanel value={selectedValue} index={1}>
              {bondsData && (
                <Bonds
                  bondsData={bondsData}
                  messages={messages}
                  locale={locale}
                />
              )}
            </TabPanel>
          </>
        ) : (
          bondsData && (
            <Bonds bondsData={bondsData} messages={messages} locale={locale} />
          )
        )}
      </StyledDiv>
    </Container>
  )
}

export default ShareBlock
