import styled from "@emotion/styled"
import { Container, NewText } from "ui"
import { sharedColors as colors } from "ui/theme/colors"
import { extractProperty } from "utils"
import Bonds from "../InvestorsRelation/Bonds"
import { InvestoreRelationsMessages } from "../InvestorsRelation/InvestorsRelation"

const StyledPageDiv = styled.div`
  padding-top: 32px;
  padding-bottom: 0px;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding-top: 80px;
    padding-bottom: 0px;
  }
`

const StyledInnerWrapper = styled.div`
  padding-top: 32px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding-top: 40px;
    justify-content: center;
  }
`

const StyledTableWrapper = styled.div`
  margin-top: 0px;
`

type Props = {
  data: any
  bondsData: any
  messages: InvestoreRelationsMessages
  children: React.ReactNode
  locale: string
}

function BondsMain({ data, bondsData, messages, children, locale }: Props) {
  const mainTitle = extractProperty(data, "mainTitle")

  return (
    <Container>
      <StyledPageDiv>
        {children}
        <StyledInnerWrapper>
          <NewText
            component={"h1"}
            desktopFontSize={40}
            desktopLineHeight={"44px"}
            desktopFontWeight={400}
            mobileFontSize={32}
            mobileLineHeight={"36px"}
            color={colors.black}
          >
            {mainTitle}
          </NewText>
          <StyledTableWrapper>
            {" "}
            {bondsData ? (
              <Bonds
                bondsData={bondsData}
                type="inner"
                messages={messages}
                locale={locale}
              />
            ) : null}
          </StyledTableWrapper>
        </StyledInnerWrapper>
      </StyledPageDiv>
    </Container>
  )
}

export default BondsMain
