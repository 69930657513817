import styled from "@emotion/styled"
import moment from "moment"
import { useTranslations } from "next-intl"
import { NewText } from "ui"
import { sharedColors as colors } from "ui/theme/colors"
import { InvestoreRelationsMessages } from "./InvestorsRelation"

const StyledTable = styled.div<{ $type?: string }>`
  border: 1px solid #6f6f6f;
  border-radius: 16px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 24px;
  width: 100%;
  ${(props) => props.theme.breakpoints.up("md")} {
    width: ${(p) => (p.$type === "inner" ? "50%" : "100%")};
    margin-top: 40px;
  }
`

const StyledItemDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const StyledTextWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    margin-left: 6px;
  }
`
const StyledLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: #6f6f6f;
`

type Props = {
  data: any
  type?: string
  messages: InvestoreRelationsMessages
}

function BondsTable({ data, type, messages }: Props) {
  const t = useTranslations("InvestorRelations")
  const tableHeader = [
    { title: t("price"), value: "securityLastRate" },
    { title: t("change"), value: "changeValue" },
    { title: t("bondVolume"), value: "bondVolume" },
    { title: t("weekRange"), value: "range52Weeks" },
    { title: t("volume"), value: "securityDailyAggVolume" },
  ]
  return (
    <StyledTable $type={type}>
      {tableHeader.map((item: any, index: any) => (
        <StyledItemDiv key={index}>
          <NewText
            component={"p"}
            desktopFontSize={24}
            desktopLineHeight={"35px"}
            desktopFontWeight={400}
            mobileFontSize={18}
            mobileLineHeight={"24px"}
            color={colors.black}
          >
            {item.title}
          </NewText>
          <StyledTextWrapper>
            <NewText
              component={"p"}
              desktopFontSize={24}
              desktopLineHeight={"35px"}
              desktopFontWeight={400}
              mobileFontSize={18}
              mobileLineHeight={"24px"}
              color={
                item?.value === "changeValue"
                  ? data?.[item.value] > 0
                    ? "#008015"
                    : "red"
                  : colors.black
              }
              style={{ unicodeBidi: "plaintext" }}
            >
              {item.value === "securityLastRate"
                ? `${data?.[item.value]}${" "}${t("cents")}`
                : item.value === "bondVolume"
                ? `${data?.[item.value]?.toLocaleString()}`
                : data?.[item.value]}
            </NewText>
          </StyledTextWrapper>
        </StyledItemDiv>
      ))}

      {type === "inner" && (
        <>
          <StyledLine />
          <NewText
            component={"p"}
            desktopFontSize={18}
            desktopLineHeight={"24px"}
            desktopFontWeight={400}
            mobileFontSize={14}
            mobileLineHeight={"18px"}
            color={colors.black}
          >
            * {messages.lastUpdate}:{" "}
            {moment(data?.lastTransaction).format("DD/MM/YYYY HH:mm")}
          </NewText>
        </>
      )}
    </StyledTable>
  )
}

export default BondsTable
