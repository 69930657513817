"use client"

import styled from "@emotion/styled"
import { Pagination, PaginationItem } from "@mui/material"
import Link from "next/link"
import { usePathname, useSearchParams } from "next/navigation"
import { useEffect, useState } from "react"
import { Container } from "ui"
import { type ProjectFilters } from "utils"
import { AllProjects } from "utils/projects"
import Filters from "./Filters"
import { filterProjects } from "./FiltersUtils/filterProjects"
import ProjectsGrid from "./ProjectsGrid"

const StyledFlex = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  ${(props) => props.theme.breakpoints.up("md")} {
    gap: 40px;
  }
`

const StyledResponse = styled.div`
  font-size: 18px;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  ${(props) => props.theme.breakpoints.up("md")} {
    gap: 40px;
    padding-top: 40px;
  }
`

const StyledPaginationDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  li {
    button {
      font-size: 16px;
      border: 1px solid #f7f7f7;
      &:last-child {
        border: none;
      }
      &:first-child {
        border: none;
      }
    }
    .Mui-selected {
      background-color: #2768a3;
    }
  }
`

export type FilterOptionState = {
  number: number
  visibility: boolean
  value: string
}
export type StateType = {
  statuses: FilterOptionState[]
  subsidiaries: FilterOptionState[]
  designations: FilterOptionState[]
  cities: FilterOptionState[]
  countries: FilterOptionState[]
}

export function initializeFilterOptions<T extends keyof ProjectFilters>(
  data: ProjectFilters[T],
): FilterOptionState[] {
  const { options } = data
  return options.map((option) => ({
    number: option.id,
    visibility: false,
    value: option.value,
  }))
}

export function initialState(filters: ProjectFilters) {
  return {
    designations: initializeFilterOptions(filters.designations),
    subsidiaries: initializeFilterOptions(filters.subsidiaries),
    statuses: initializeFilterOptions(filters.statuses),
    countries: initializeFilterOptions(filters.countries),
    cities: initializeFilterOptions(filters.cities),
  }
}

export function restoreObjectFromSearchParams(
  queryString: string,
  initialData: StateType,
): StateType {
  const params = new URLSearchParams(queryString)
  const restoredObject: StateType = { ...initialData }

  params.forEach((value, key) => {
    if (restoredObject[key as keyof StateType]) {
      restoredObject[key as keyof StateType] = restoredObject[key as keyof StateType].map(
        (item) => ({
          ...item,
          // Set visibility to true if the value matches; otherwise retain the original visibility
          visibility: item.value === value ? true : item.visibility,
        }),
      )
    }
  })

  return restoredObject
}

const allHebrew = "כל+הפרויקטים"
const allEnglish = "All+Projects"

function checkFiltered(query: string) {
  const includesKeywords =
    query.includes("statuses") || query.includes("countries") || query.includes("cities")

  const includesDesignations =
    query.includes("designations") &&
    !query.includes(encodeURIComponent(allHebrew)) &&
    !query.includes(encodeURIComponent(allEnglish))

  if (includesKeywords || includesDesignations) {
    return true
  } else {
    return false
  }
}

const projectsPerPage = 9

const getProjectsPerPage = (page: number, projects: AllProjects) => {
  const startIndex = (page - 1) * projectsPerPage
  const endIndex = startIndex + projectsPerPage
  const projectsForPage = projects.slice(startIndex, endIndex)
  return projectsForPage
}

function ProjectsLayout({
  filters,
  projects,
  messages,
  searchText,
  locale,
  resultRef,
  // projectsForPage = { projects },
  page = "/projects",
}: // hideFilters,
{
  projects: AllProjects
  filters: ProjectFilters
  relativeProjectsPath?: boolean
  searchText?: string | null
  locale: string
  resultRef?: any
  projectsForPage: any
  page?: string
  messages: {
    showAll: string
    all: string
    showResults: string
    noProjectsFound: string
    toProject: string
    filters: string
    moreCategory: string
    allProjects: string
  }
  hideFilters?: boolean
}) {
  const searchParams = useSearchParams()
  const queryString = searchParams.toString()
  const stateWithParams = restoreObjectFromSearchParams(queryString, initialState(filters))
  const filteredPage = Number(searchParams.get("page") || 1)
  const [pageNum, setPageNum] = useState(filteredPage)
  const [state, setState] = useState<StateType>(stateWithParams)
  const [interimState, setInterimState] = useState<StateType>(state)
  const [startIndex, setStartIndex] = useState(0)
  const [endIndex, setEndIndex] = useState(9)

  const [isFilterApplied, setIsFilterApplied] = useState(
    queryString && checkFiltered(queryString) ? true : false,
  )
  const pathname = usePathname()
  const projectsForPage = getProjectsPerPage(pageNum, projects)
  const filteredProjects = searchText
    ? filterProjects(projects, state).filter((el) =>
        el.projectTitle?.toLowerCase().includes(searchText.toLowerCase()),
      )
    : isFilterApplied
    ? filterProjects(projects, state)?.filter(() => true)
    : filterProjects(projectsForPage, state)?.filter(() => true)

  const projectsPerPage = 9

  const totalPages = Math.ceil(
    searchText || isFilterApplied
      ? filteredProjects.length / projectsPerPage
      : projects.length / projectsPerPage,
  )
  const slicedProjets = filteredProjects?.slice(
    startIndex,
    endIndex > filteredProjects.length ? filteredProjects.length : endIndex,
  )
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPageNum(value)
    window.scrollTo({ top: 0, behavior: "smooth" }) // Scroll to top smoothly

    if (searchText || isFilterApplied) {
      const startIndex = (value - 1) * projectsPerPage
      const endIndex = startIndex + projectsPerPage
      setEndIndex(endIndex)
      setStartIndex(startIndex)
    }
  }
  const handleUnfilteredChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPageNum(value)
    window.scrollTo({ top: 0, behavior: "smooth" }) // Scroll to top smoothly
  }
  useEffect(() => {
    const allDesignations = state.designations[0].visibility
    if (isFilterApplied) {
      const newSearchParams = new URLSearchParams()
      let onlyAllDesignations = allDesignations
      Object.keys(state).forEach((key) => {
        state[key as keyof StateType].forEach((item) => {
          if (item.visibility) {
            if (key !== "designations" && key !== "page") {
              onlyAllDesignations = false
            }
            newSearchParams.append(key, item.value)
          }
        })
      })
      if (pageNum > 1 && totalPages >= pageNum) {
        newSearchParams.set("page", pageNum.toString())
      } else {
        newSearchParams.set("page", "1")
      }
      const newQueryString = newSearchParams.toString()
      window.history.pushState(null, "", `${pathname}?${newQueryString}`)
      if (onlyAllDesignations) {
        setIsFilterApplied(false)
      }
    } else {
      const allDesign = locale === "en" ? allEnglish : "כל הפרויקטים"
      // const allDesign = locale === "en" ? allEnglish : allHebrew
      const newSearchParams = new URLSearchParams()
      newSearchParams.set("designations", allDesign)

      if (pageNum) {
        newSearchParams.set("page", pageNum.toString())
      }

      const newQueryString = newSearchParams.toString()
      window.history.pushState(null, "", `${pathname}?${newQueryString}`)
    }
  }, [state, pageNum, pathname, isFilterApplied, totalPages, queryString, searchParams, locale])
  return (
    <Container containerRef={resultRef}>
      <StyledFlex>
        <Filters
          interimState={interimState}
          setInterimState={setInterimState}
          state={state}
          setState={setState}
          filters={filters}
          element="הפרוייקטים שלנו"
          locale={locale}
          setIsFilterApplied={setIsFilterApplied}
          page={page}
          projects={projects}
          messages={messages}
        />

        {filteredProjects?.length > 0 ? (
          <StyledResponse>
            <ProjectsGrid
              locale={locale}
              projects={searchText || isFilterApplied ? slicedProjets : filteredProjects}
              filters={filters}
              toProject={messages.toProject}
              searchText={searchText}
            />
            {isFilterApplied ? (
              <StyledPaginationDiv>
                <Pagination
                  count={totalPages}
                  color="primary"
                  defaultPage={1}
                  page={pageNum}
                  onChange={handleChange}
                  renderItem={(item) => (
                    <PaginationItem
                      component={Link}
                      href={`${pathname}?page=${item.page}`}
                      {...item}
                    />
                  )}
                />
              </StyledPaginationDiv>
            ) : (
              <StyledPaginationDiv>
                <Pagination
                  count={totalPages}
                  color="primary"
                  defaultPage={1}
                  onChange={handleUnfilteredChange}
                  page={pageNum}
                  renderItem={(item) => (
                    <PaginationItem
                      component={Link}
                      href={`${pathname}?page=${item.page}`}
                      {...item}
                    />
                  )}
                />
              </StyledPaginationDiv>
            )}
          </StyledResponse>
        ) : (
          <StyledResponse>{messages.noProjectsFound}</StyledResponse>
        )}
      </StyledFlex>
    </Container>
  )
}

export default ProjectsLayout
