import styled from "@emotion/styled"
import { Container, TitleTextLink } from "ui"

const StyledDiv = styled.div`
  padding-top: 32px;
  padding-bottom: 32px;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding-top: 60px;
    padding-bottom: 60px;
  }
`

type Props = {
  data: any
  children: React.ReactNode
}

function TitleWithButton({ data, children }: Props) {
  return (
    <Container>
      <StyledDiv>
        {children}
        <TitleTextLink data={data} />
      </StyledDiv>
    </Container>
  )
}
export default TitleWithButton
