import {
  GetComponentQuery,
  GetProjectsDetailsQuery,
  IGroupEntrepreneur,
  IGroupMemmber,
  IGroupPartner,
  IProjectStatus,
  IPropertyType,
} from "apollo"
import {
  AllProjects,
  ContentList,
  UndStr,
  extractAllProjects,
} from "./projects"

export function convertISODateString(dateStr?: string) {
  if (!dateStr) return
  const dateObj = new Date(dateStr)
  return new Intl.DateTimeFormat("en-GB").format(dateObj)
}

export function getNonUmbracoURL({
  url,
  toDelete,
}: {
  url: string
  toDelete: string
}) {
  return url.replace(toDelete, "")
}

export type StatusTitles = {
  title: string | undefined
  id: number | null | undefined
}[]
export type EdgesArray = NonNullable<
  NonNullable<GetComponentQuery["components"]>["edges"]
>

type Nodes = NonNullable<
  NonNullable<GetProjectsDetailsQuery["filters"]>["nodes"]
>

export function extractStatuses(data: Nodes): StatusTitles {
  return (
    data?.map((el) => {
      const properties = el?.namedProperties as IProjectStatus
      return {
        title: properties.statusLisi?.value as string | undefined,
        id: el?.id,
      }
    }) ?? []
  )
}

export type MatchedStatuses = {
  name: string | null | undefined
  id: number
  title: string | undefined
}[]

export function matchStatuses(
  projectStatuses: ContentList[],
  data: Nodes
): MatchedStatuses {
  const statusTitles = extractStatuses(data)
  const matchedStatuses = []
  for (const status of projectStatuses) {
    const correspondingTitle = statusTitles?.find(
      (titleObj) => titleObj.id === status.id
    )
    if (correspondingTitle) {
      matchedStatuses.push({
        name: status.name,
        id: status.id,
        title: correspondingTitle.title,
      })
    }
  }
  return matchedStatuses
}

function extractPartners({
  data,
  type,
}: {
  data: Nodes
  type: "partner" | "entrepreneur" | "member"
}) {
  return data.map((el) => {
    if (type === "member") {
      const properties = el?.namedProperties as IGroupMemmber
      return {
        memberName: properties.memberName?.value as UndStr,
        url: properties.url?.links?.[0]?.url as UndStr,
        lOGO: properties.lOGO?.mediaItems?.[0]?.url as UndStr,
      }
    } else if (type === "entrepreneur") {
      const properties = el?.namedProperties as IGroupEntrepreneur
      return {
        memberName: properties.entrepreneurName?.value as UndStr,
        url: properties.url?.links?.[0]?.url as UndStr,
        lOGO: properties.lOGO?.mediaItems?.[0]?.url as UndStr,
      }
    } else if (type === "partner") {
      const properties = el?.namedProperties as IGroupPartner
      return {
        memberName: properties.partnerName?.value as UndStr,
        lOGO: undefined,
        url: undefined,
      }
    } else {
      return {
        lOGO: undefined,
        memberName: undefined,
        url: undefined,
      }
    }
  })
}
function extractPropertyName(data: Nodes, propertyName: string) {
  const result = data.map((el) => {
    const properties = el?.namedProperties as any
    const value = properties[propertyName].value
    if (!value) {
      return
    }
    return value
  })
  const filteredResult = result.filter((item): item is string => item !== null)
  return filteredResult
}

function extractRelatedOptions(data: Nodes, propertyName: string) {
  const result = data.map((el) => {
    const properties = el?.namedProperties as any
    const value = properties[propertyName].value
    if (!value) {
      return
    }
    return { id: el?.id, value }
  })
  const filteredResult = result.filter((el) => el?.value !== undefined) as {
    id: number
    value: string
  }[]
  return filteredResult
}

function extractRelatedProjects(data: Nodes, array: Nodes) {
  const statuses = extractRelatedOptions(
    array.filter((el) => el?.contentType?.alias === "projectStatus"),
    "statusLisi"
  )
  const cities = extractRelatedOptions(
    array.filter((el) => el?.contentType?.alias === "groupPartner12"),
    "partnerName"
  )
  const countries = extractRelatedOptions(
    array.filter((el) => el?.contentType?.alias === "groupPartner1"),
    "partnerName"
  )
  const subsidiaries = extractRelatedOptions(
    array.filter((el) => el?.contentType?.alias === "groupSite"),
    "siteName"
  )

  const designations = extractRelatedOptions(
    array.filter((el) => el?.contentType?.alias === "propertyDesignation"),
    "Designation"
  )
  const relatedProjects = extractAllProjects(data)
  return {
    relatedProjects,
    countries,
    subsidiaries,
    cities,
    statuses,
    designations,
  }
}

export type RelatedProjects = {
  relatedProjects: AllProjects
  countries: {
    id: number
    value: string
  }[]
  subsidiaries: {
    id: number
    value: string
  }[]
  cities: {
    id: number
    value: string
  }[]
  statuses: {
    id: number
    value: string
  }[]
  designations: {
    id: number
    value: string
  }[]
}
function extractPropertyType(data: Nodes) {
  const result = data.map((el) => {
    const properties = el?.namedProperties as IPropertyType
    const value = properties.propertyTypeName?.value as string | undefined
    if (!value) {
      return
    }
    return { value, id: el?.id }
  })
  const filteredResult = result.filter(
    (item): item is { value: string; id: number } => item?.id !== null
  )
  return filteredResult
}

export type Group = {
  memberName: string | undefined
  url: string | undefined
  lOGO: string | undefined
}[]

export type Groups = {
  companies: Group
  entrepreneurs: Group
  partners: Group
}
type Props = {
  data: GetProjectsDetailsQuery
  statusArray: number[]
  groupCompanies: number[]
  groupEntrepreneurs: number[]
  groupPartners: number[]
  propertyDesignations: number[]
  projectStatuses: ContentList[]
  citiesList: number[]
  countriesList: number[]
  relatedProjectIDs: number[]
  relatedProjectsBool?: boolean
}
export function matchFetchedChildren({
  data,
  groupCompanies,
  groupEntrepreneurs,
  groupPartners,
  statusArray,
  projectStatuses,
  propertyDesignations,
  citiesList,
  countriesList,
  relatedProjectsBool,
}: Props) {
  const companiesArr: Nodes = []
  const entrepreneursArr: Nodes = []
  const partnersArr: Nodes = []
  const statusesArr: Nodes = []
  const designationsArray: Nodes = []
  const cityArray: Nodes = []
  const countryArray: Nodes = []
  const propertyTypesArray: Nodes = []
  const array = data.filters?.nodes!
  array?.forEach((el) => {
    const id = el?.id
    if (!id) return
    if (groupCompanies.includes(id)) {
      companiesArr.push(el)
    } else if (groupEntrepreneurs.includes(id)) {
      entrepreneursArr.push(el)
    } else if (groupPartners.includes(id)) {
      partnersArr.push(el)
    } else if (statusArray.includes(id)) {
      statusesArr.push(el)
    } else if (propertyDesignations.includes(id)) {
      designationsArray.push(el)
    } else if (citiesList.includes(id)) {
      cityArray.push(el)
    } else if (countriesList.includes(id)) {
      countryArray.push(el)
    }
  })
  const companies = extractPartners({ data: companiesArr, type: "member" })
  const entrepreneurs = extractPartners({
    data: entrepreneursArr,
    type: "entrepreneur",
  })
  const partners = extractPartners({ data: partnersArr, type: "partner" })
  const designations = extractPropertyName(designationsArray, "Designation")
  const cities = extractPropertyName(cityArray, "partnerName")
  const countries = extractPropertyName(countryArray, "partnerName")
  const statuses = matchStatuses(projectStatuses, statusesArr)
  const propertyTypes = extractPropertyType(propertyTypesArray)
  let relatedProjects: RelatedProjects | undefined
  if (relatedProjectsBool) {
    relatedProjects = extractRelatedProjects(data.projects?.nodes || [], array)
  }
  const groups: Groups = {
    companies,
    entrepreneurs,
    partners,
  }
  return {
    groups,
    statuses,
    designations,
    cities,
    countries,
    propertyTypes,
    relatedProjects,
  }
}
