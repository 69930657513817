import styled from "@emotion/styled"
import { Box, Button, Modal } from "@mui/material"
import { useLocale } from "next-intl"
import { useState } from "react"
import {
  CalendarIcon,
  Container,
  CustomBreadcrumbs,
  Desktop,
  DividerIcon,
  LocationIcon,
  Mobile,
  NewText,
  ShareIcon,
} from "ui"
import { sharedColors as colors } from "ui/theme/colors"
import { Job } from "utils"
import { convertISODateString } from "utils/utils"
import FormComponent from "./FormComponent"
const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 32px;
  margin-top: 32px;
  margin-bottom: 0px;
  justify-items: center;
  border-bottom: 1px solid var(--Grey, #6f6f6f);
  padding-bottom: 32px;

  ${(props) => props.theme.breakpoints.up("md")} {
    margin-top: 0px;
    margin-bottom: 80px;
    grid-gap: 80px;
    padding-bottom: 80px;

    grid-template-columns: 1fr 430px;
  }
`
const StyledBreadCrumbs = styled.div`
  padding-bottom: 32px;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding-bottom: 40px;
    padding-top: 40px;
  }
`

const StyledFlex = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin: 16px 0px;
`
const StyledBox = styled(Box)`
  justify-self: baseline;
  ${(props) => props.theme.breakpoints.up("md")} {
    grid-column: 1 / 2;
    grid-row: 1 / 3;
  }
`
const StyledItem = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`
const StyledSpace = styled.div`
  height: 32px;
  ${(props) => props.theme.breakpoints.up("md")} {
    height: 24px;
  }
`

const StyledTextWrapper = styled.div`
  display: flex;
  align-items: baseline;
  p {
    max-width: 450px;
  }
`

const StyledDot = styled.div`
  width: 8px;
  height: 8px;
  background-color: #2768a3;
  margin-right: 8px;
  border-radius: 50%;
  flex-shrink: 0;
`

const StyledSplitContent = styled.div`
  margin-top: 40px;
  &:first-of-type {
    margin-top: 0px;
  }
  span {
    font-size: inherit !important;
  }
`

const StyledButton = styled(Button)`
  width: 174px;
  height: 48px;
  padding: 8px, 16px, 8px, 16px;
  border: 2px solid black;
  border-radius: 8px;
  outline: none;
  font-size: 20px;
  font-weight: 600;
  text-decoration: none;
  width: auto;
`

const StyledModal = styled(Modal)`
  top: unset;
  bottom: 0;
  max-height: calc(100% - 80px);
  overflow: scroll;
  form {
  }
`

function TopContent({ job, messages }: { job: Job; messages?: any }) {
  const onShare = async () => {
    const shareData = {
      title: job?.jobName || "",
      url: window.location.href,
    }
    if (navigator.share && navigator.canShare(shareData)) {
      try {
        await navigator.share(shareData)
      } catch (err) {
        console.error(err)
      }
    } else {
      navigator.clipboard.writeText(shareData.url)
    }
  }
  const mainTitle = "קבוצת אשטרום בונה לכם קריירה"
  const subtitle = job?.professionName
  const position = job?.jobName
  const content = job?.description
  const location = job?.areaName
  const date = convertISODateString(job?.startAdvertisingDate)
  const jobNumber = `מספר משרה - ${job?.orderId}`

  const splitContent = content?.split("\n\n") ? content?.split("\n\n") : []
  const [modalOpen, setModalOpen] = useState(false)
  const locale = useLocale()

  return (
    <Container>
      <Mobile>
        <CustomBreadcrumbs
          current={{ title: jobNumber, url: `/` }}
          parents={[
            { title: "ראשי", url: "/" },
            { title: "קריירה", url: "/career" },
          ]}
        />
      </Mobile>

      <Desktop>
        <StyledBreadCrumbs>
          <CustomBreadcrumbs
            current={{ title: jobNumber, url: `/` }}
            noPadding={true}
            parents={[
              { title: "ראשי", url: "/" },
              { title: "קריירה", url: "/career" },
            ]}
          />
        </StyledBreadCrumbs>
        <Box>
          <NewText
            component="h1"
            desktopFontSize={40}
            desktopLineHeight="44px"
            mobileFontSize={32}
            mobileLineHeight="36px"
            color={colors.black}
          >
            {mainTitle}
          </NewText>
          <StyledSpace />
          <NewText
            component="p"
            desktopFontSize={24}
            desktopLineHeight="25.92px"
            mobileFontSize={18}
            mobileLineHeight="20px"
            color={colors.gray}
          >
            {subtitle}
          </NewText>
        </Box>
      </Desktop>
      <StyledGrid>
        <StyledBox>
          <Mobile>
            <Box>
              <NewText
                component="h1"
                desktopFontSize={40}
                desktopLineHeight="44px"
                mobileFontSize={32}
                mobileLineHeight="36px"
                color={colors.black}
              >
                {mainTitle}
              </NewText>
              <StyledSpace />
              <NewText
                component="p"
                desktopFontSize={24}
                desktopLineHeight="25.92px"
                mobileFontSize={18}
                mobileLineHeight="20px"
                color={colors.gray}
              >
                {subtitle}
              </NewText>
            </Box>
          </Mobile>
          <Box>
            <NewText
              component="p"
              desktopFontSize={32}
              desktopLineHeight="36px"
              mobileFontSize={24}
              mobileLineHeight="28px"
              color={colors.black}
            >
              {position}
            </NewText>
            <StyledFlex>
              <StyledItem>
                <LocationIcon />
                <NewText
                  component="p"
                  desktopFontSize={20}
                  desktopLineHeight="24px"
                  mobileFontSize={16}
                  mobileLineHeight="24px"
                  color={colors.black}
                >
                  {location}
                </NewText>
              </StyledItem>
              <DividerIcon />
              <StyledItem>
                <CalendarIcon />
                <NewText
                  component="p"
                  desktopFontSize={20}
                  desktopLineHeight="24px"
                  mobileFontSize={16}
                  mobileLineHeight="24px"
                  color={colors.black}
                >
                  {date}
                </NewText>
              </StyledItem>
              <DividerIcon />
              <StyledItem>
                <NewText
                  component="p"
                  desktopFontSize={20}
                  desktopLineHeight="24px"
                  mobileFontSize={16}
                  mobileLineHeight="24px"
                  color={colors.black}
                >
                  {jobNumber}
                </NewText>
              </StyledItem>
            </StyledFlex>
            <Mobile>
              <StyledButton onClick={() => setModalOpen(true)}>
                {locale === "he" ? "הגש מועמדות עכשיו" : "Apply Now"}
              </StyledButton>
            </Mobile>
            <Button
              variant="text"
              sx={{
                padding: 0,
                minWidth: 0,
                fontWeight: 400,
                fontSize: "20px",
                display: "flex",
                gap: "8px",
              }}
              onClick={onShare}
            >
              <ShareIcon width={20} height={20} />
              {messages?.sharePos ? messages?.sharePos : "שיתוף משרה"}
            </Button>

            {splitContent.map((content, index) => {
              const splitContent = content.split("\n")
              return (
                <StyledSplitContent key={index.toString()}>
                  <NewText
                    component="p"
                    desktopFontSize={24}
                    desktopLineHeight="34px"
                    mobileFontSize={18}
                    mobileLineHeight="24px"
                    desktopFontWeight={600}
                    color={colors.black}
                    dangerouslySetInnerHTML={{ __html: splitContent[0] }}
                  />
                  {splitContent.slice(1).map((item, index) => {
                    return (
                      /\S/.test(item) && (
                        <StyledTextWrapper key={index}>
                          {item.includes("-") && <StyledDot />}
                          <NewText
                            component="p"
                            desktopFontSize={24}
                            desktopLineHeight="34px"
                            mobileFontSize={18}
                            mobileLineHeight="24px"
                            desktopFontWeight={400}
                            color={colors.black}
                            dangerouslySetInnerHTML={{
                              __html: item
                                .replace(/^-/, "")
                                .replace(/>-/g, ">")
                                .replace("-&nbsp;", ""),
                            }}
                          />
                        </StyledTextWrapper>
                      )
                    )
                  })}
                </StyledSplitContent>
              )
            })}
          </Box>
        </StyledBox>

        <Mobile>
          <StyledModal open={modalOpen} onClose={() => setModalOpen(false)}>
            <FormComponent
              orderId={job?.orderId}
              jobType={job?.jobName}
              messages={messages}
              setModalOpen={setModalOpen}
            />
          </StyledModal>
        </Mobile>
        <Desktop>
          <FormComponent
            orderId={job?.orderId}
            jobType={job?.jobName}
            messages={messages}
          />
        </Desktop>
      </StyledGrid>
    </Container>
  )
}

export default TopContent
