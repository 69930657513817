import styled from "@emotion/styled"
import { IconButton } from "@mui/material"
import Image from "next/image"
import { useState } from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import { Container, Desktop, LeftArrow, RightArrow } from "ui"
import { sharedColors as colors } from "ui/theme/colors"
import { extractProperty } from "utils"
import GalleryItem from "./GalleryItem"

const StyledBottomContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 10px;
  right: 0px;
  z-index: 999;
`
const StyledPaginatorContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const StyledBulletsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 0 4px;
`

const StyledBullet = styled.div<{ active: boolean }>`
  width: 6px;
  box-sizing: border-box;
  height: 6px;
  border-radius: 50%;
  margin: 0 2px;
  cursor: pointer;
  background-color: ${(props) => (props.active ? colors.white : "transparent")};
  border: 1px solid #fff;
`

const StyledContainer = styled.div`
  background-color: #2768a3;
  padding: 32px 0px;
  .swiper {
    padding: 0px !important;
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    padding: 80px 0px;
  }
`

const StyledRelative = styled.div`
  position: relative;
`

const StyledAbsoluteImage = styled.div`
  width: 105px;
  height: 80px;
  position: absolute;
  top: 24px;
  right: 0px;
  ${(props) => props.theme.breakpoints.up("md")} {
    width: 157.86px;
    height: 120px;
    top: 0px;
  }
`

type Props = {
  data: any
  locale: string
}

function JobsQuoteGallery({ data, locale }: Props) {
  const dataToRender = data?.propertyValue?.blocks?.[0]
  const galleryItems = extractProperty(dataToRender, "jobsQuoteGalleryItems")
  const [swiperRef, setSwiperRef] = useState<any>(null)
  const [activeIndex, setActiveIndex] = useState<number>(0)
  if (!galleryItems.length) {
    return null
  }
  return (
    <StyledContainer>
      <Container>
        <StyledRelative>
          <Swiper
            onSwiper={setSwiperRef}
            onSlideChange={(swiper) => {
              setActiveIndex(swiper.activeIndex)
            }}
          >
            {galleryItems &&
              galleryItems.map((item: any, index: number) => (
                <SwiperSlide virtualIndex={index} key={index.toString()}>
                  <GalleryItem item={item} key={index.toString()} />
                </SwiperSlide>
              ))}
          </Swiper>
          <StyledAbsoluteImage>
            <Image src="/recomm-desktop.png" fill alt="Test" />
          </StyledAbsoluteImage>
          <StyledBottomContainer>
            <StyledPaginatorContainer>
              <Desktop>
                <IconButton
                  onClick={() => {
                    swiperRef.slidePrev()
                  }}
                >
                  {locale === "en" ? (
                    <LeftArrow fill="#FFF" />
                  ) : (
                    <RightArrow fill="#FFF" />
                  )}
                </IconButton>
              </Desktop>
              <StyledBulletsContainer>
                {galleryItems &&
                  galleryItems.map((_: any, index: number) => (
                    <StyledBullet
                      key={index.toString()}
                      onClick={() => {
                        swiperRef.slideTo(index)
                      }}
                      active={index === activeIndex}
                    />
                  ))}
              </StyledBulletsContainer>
              <Desktop>
                <IconButton
                  onClick={() => {
                    swiperRef.slideNext()
                  }}
                >
                  {locale === "en" ? (
                    <RightArrow fill="#FFF" />
                  ) : (
                    <LeftArrow fill="#FFF" />
                  )}
                </IconButton>
              </Desktop>
            </StyledPaginatorContainer>
          </StyledBottomContainer>
        </StyledRelative>
      </Container>
    </StyledContainer>
  )
}

export default JobsQuoteGallery
