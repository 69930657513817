"use client"

import { BlockRenderer, Container, CustomBreadcrumbs, TopBanner } from "ui"
import { findProperty, getBreadCrumbs } from "utils"
import FinancialReportsTab from "./FinancialReportsTab"

type Props = {
  data: any
  view: string
  locale: string
}

function FinancialReports({ data, view, locale }: Props) {
  const { selfObject, parents } = getBreadCrumbs(data, locale)
  const components = {
    topBanner: TopBanner,
    financialReportsMain: FinancialReportsTab,
  }
  const blocks = findProperty(data.page, "blocks")?.blocks || []
  const hasBanner = blocks?.some(
    (block: any) => block.blockType === "topBanner" || block.blockType === "HeaderWithHeroAndText",
  )
  return (
    <>
      <Container>
        <CustomBreadcrumbs current={selfObject} parents={parents} />
      </Container>

      {blocks?.map((block: any, index: number) => {
        let additionalProps = {}
        if (block.blockType === "contentText") {
          additionalProps = {
            hasBanner,
            view,
            eventName: block.blockType === "financialReportsMain" && "דוחות כספיים",
          }
        } else {
          additionalProps = {
            view,
            eventName: block.blockType === "financialReportsMain" && "דוחות כספיים",
          }
        }
        return (
          <BlockRenderer
            key={index}
            blockType={block.blockType}
            blockData={block}
            props={additionalProps}
            components={components}
          />
        )
      })}
    </>
  )
}

export default FinancialReports
