"use client"
import styled from "@emotion/styled"
import { Container, NewText } from "ui"

import { useLocale } from "next-intl"
import { extractProperty } from "utils"
import ContactBox from "./ContactBox"

const StyledContainer = styled.section<{ project?: boolean }>`
  padding: ${(props) => (props.project ? `40px 0 0 0` : `32px 0px 16px 0px`)};
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding-top: ${(props) => (props.project ? `80px` : `40px`)};
  }
`

const StyledTitlesContainer = styled.div<{ project?: boolean }>`
  display: flex;
  gap: 15px;
  align-items: center;
`

const StyledNewText = styled(NewText)<{ project?: boolean }>`
  text-align: left;
  ${(props) => props.theme.breakpoints.up("md")} {
    text-align: ${(props) => (props.project ? `left` : `center`)};
  }
`

const StyledBoxesContainer = styled.div<{
  project?: boolean
  itemCount: number
}>`
  margin-top: ${(props) => (props.project ? `16px` : `24px`)};
  display: flex;
  flex-direction: column;
  padding: 0;
  gap: 8px;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding-bottom: ${(props) => (props.project ? `0` : `40px`)};
    margin-top: ${(props) => (props.project ? `24px` : `40px`)};
    gap: 16px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    div {
      width: 100%;
    }
  }
`
const StyledDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #6f6f6f;
  display: none;
  margin: 40px 0 0 0;
  ${(props) => props.theme.breakpoints.up("md")} {
    display: block;
  }
`

type Props = {
  data: any
  project?: boolean
}
// efwf43w
export const ContactContent: React.FC<Props> = ({ data, project }) => {
  const title = extractProperty(data, "main")
  const subText = extractProperty(data, "subText")
  const contactInfoBoxes = extractProperty(data, "contactInfoBoxes")
  const enCheckbox = extractProperty(data, "enCheckbox")
  const locale = useLocale()
  return (
    <Container>
      <StyledContainer project={project} id="contact-us">
        <StyledTitlesContainer project={project}>
          <StyledNewText
            project={project}
            desktopFontSize={32}
            desktopFontWeight="400"
            desktopLineHeight="36px"
            mobileFontSize={24}
            mobileFontWeight="400"
            mobileLineHeight={"34.56px"}
            component="h1"
            color={"black"}
          >
            {title}
          </StyledNewText>
          <StyledNewText
            project={project}
            desktopFontSize={24}
            desktopFontWeight="400"
            desktopLineHeight="25.92px"
            mobileFontSize={18}
            mobileFontWeight="400"
            mobileLineHeight={"20px"}
            component="p"
            color={"black"}
          >
            {subText}
          </StyledNewText>
        </StyledTitlesContainer>
        <StyledBoxesContainer project={project} itemCount={4}>
          {contactInfoBoxes.map((i: any, key: number) => (
            <ContactBox
              key={key.toString()}
              data={i}
              locale={enCheckbox || locale === "en" ? "en" : "il"}
            />
          ))}
        </StyledBoxesContainer>
        {!project ? <StyledDivider /> : null}
      </StyledContainer>
    </Container>
  )
}

export default ContactContent
