import styled from "@emotion/styled"
import { Pagination } from "swiper"
import "swiper/css"
import {
  Container,
  Desktop,
  LinkButton,
  Mobile,
  NewText,
  ReportsWrapper,
  TransitionUp,
  transitionDelayTime,
  useInViewConfig,
} from "ui"
import SectionTitle from "ui/SectionTitle"
import { sharedColors } from "ui/theme/colors"

import { useInView } from "react-intersection-observer"
import "swiper/css/pagination"
import { Swiper, SwiperSlide } from "swiper/react"
import { extractProperty } from "utils/extractProperty"

const StyledDiv = styled.div`
  padding-top: 40px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;
`
const StyledBottomWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 55px;
  ${(props) => props.theme.breakpoints.down("sm")} {
    position: absolute;
    bottom: 32px;
    margin-top: 0px;
    right: 16px;
    z-index: 1;
  }
`

const StyledSwiper = styled(Swiper)`
  padding-top: 32px;
  padding-bottom: 64px;
  ${(props) => props.theme.breakpoints.down("sm")} {
    padding-bottom: 80px !important;
    .swiper-pagination {
      bottom: 16px !important;
    }
  }

  .swiper-pagination {
    width: 100px;
    left: 0px;
    bottom: 0px;
  }
`

const StyledWrapper = styled.div`
  ${(props) => props.theme.breakpoints.down("sm")} {
    position: relative;
    margin-top: 32px;
    margin-bottom: 32px;
    border-top: 1px solid rgba(111, 111, 111, 1);
    border-bottom: 1px solid rgba(111, 111, 111, 1);
    padding-top: 32px;
    padding-bottom: 32px;

    > div {
      margin-top: 0px !important;
    }
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    padding-bottom: 80px;
  }
`

type Props = {
  data: any
}

function ImmediateReports({ data }: Props) {
  const title = extractProperty(data, "title")
  const textLine = extractProperty(data, "textLine")
  const links = extractProperty(data, "linkForMore")
  const reportsBlock = extractProperty(data, "reportsBlock")
  const { ref, inView } = useInView(useInViewConfig)

  return (
    <StyledWrapper>
      <Container style={{ marginTop: "80px" }} containerRef={ref}>
        <SectionTitle
          title={title}
          accentColor={sharedColors.propBlue}
          accent={false}
        />
        <NewText
          desktopFontSize={24}
          desktopFontWeight="400"
          desktopLineHeight="35px"
          mobileFontSize={18}
          mobileFontWeight="400"
          mobileLineHeight={"24px"}
          component="h2"
          color={"black"}
        >
          {textLine}
        </NewText>
        <Desktop>
          <StyledDiv>
            {reportsBlock.map((data: any, index: number) => (
              <TransitionUp
                key={index}
                $inView={inView}
                $delay={index * transitionDelayTime}
              >
                <ReportsWrapper data={data} mainTitle={title} />
              </TransitionUp>
            ))}
          </StyledDiv>
        </Desktop>
      </Container>
      <Container forSwiper>
        <Mobile>
          <StyledSwiper
            modules={[Pagination]}
            slidesPerView={1.2}
            spaceBetween={24}
            style={{ padding: "32px 16px" }}
            pagination={{
              clickable: true,
            }}
          >
            {reportsBlock.map((data: any, index: number) => (
              <SwiperSlide key={index.toString()}>
                <ReportsWrapper key={index} data={data} mainTitle={title} />
              </SwiperSlide>
            ))}
          </StyledSwiper>
        </Mobile>
      </Container>
      <Container>
        {" "}
        <StyledBottomWrapper>
          {links && links[0]?.url && (
            <LinkButton link={links} mainTitle={title} />
          )}
        </StyledBottomWrapper>
      </Container>
    </StyledWrapper>
  )
}

export default ImmediateReports
