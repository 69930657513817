import { AllProjects } from "utils/projects"
import { StateType } from "../ProjectsLayout"

const isAnyFilterActive = (filterCategory: keyof StateType, state: StateType) =>
  state[filterCategory].some((filter) => {
    if (filter.number === 0) {
      return false
    }
    return filter.visibility
  })

const getSelectedIds = (filterCategory: keyof StateType, state: StateType) =>
  state[filterCategory].filter((filter) => filter.visibility).map((filter) => filter.number)

export const filterProjects = (projects: AllProjects, state: StateType) => {
  return projects?.filter((project) => {
    const selectedStatusIds = getSelectedIds("statuses", state)
    const selectedSubsidiaryIds = getSelectedIds("subsidiaries", state)
    const selectedDesignationIds = getSelectedIds("designations", state)
    const selectedCities = getSelectedIds("cities", state)
    const selectedCountries = getSelectedIds("countries", state)

    const matchesStatuses =
      !isAnyFilterActive("statuses", state) ||
      project.projectStatuses.some((status) => selectedStatusIds.includes(Number(status.id)))
    const matchesSubsidiaries =
      !isAnyFilterActive("subsidiaries", state) ||
      project.groupSites.some((site) => selectedSubsidiaryIds.includes(Number(site.id)))
    const matchesDesignations =
      !isAnyFilterActive("designations", state) ||
      project.propertyDesignations.some((designation) =>
        selectedDesignationIds.includes(Number(designation.id)),
      )
    const matchesCities =
      !isAnyFilterActive("cities", state) ||
      project.city.some((designation) => selectedCities.includes(Number(designation.id)))
    const matchesCountries =
      !isAnyFilterActive("countries", state) ||
      project.country.some((designation) => selectedCountries.includes(Number(designation.id)))

    return (
      matchesStatuses &&
      matchesSubsidiaries &&
      matchesDesignations &&
      matchesCities &&
      matchesCountries
    )
  })
}
