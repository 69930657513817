"use client"

import styled from "@emotion/styled"
import Image from "next/image"
import Link from "next/link"
import { Container, Desktop, Mobile, NewText } from "ui"

const StyledContainer = styled.div`
  padding-top: 32px;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding-top: 60px;
    padding-bottom: 20px;
  }
`

const StyledInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  ${(props) => props.theme.breakpoints.up("md")} {
    gap: 24px;
  }
`

const StyledNewText = styled(NewText)`
  p {
    margin: 0;
  }
`

const BoxGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 32px;
  ${(props) => props.theme.breakpoints.up("md")} {
    row-gap: 24px;
    column-gap: 32px;
    grid-template-columns: 1fr 1fr;
  }
`

const BoxContainer = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-radius: 16px;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.08);
  ${(props) => props.theme.breakpoints.up("md")} {
    min-height: 174px;
  }
`

const StyledLink = styled(Link)`
  font-size: 16px;
  ${(props) => props.theme.breakpoints.up("md")} {
    font-size: 18px;
  }
`

const HeaderFlex = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`

export default function LotteryRegister({
  lotteryWays,
  lotterRegisterTitle,
}: {
  lotteryWays: {
    icon?: string
    title?: string
    description?: string
    linkName?: string
    linkURL?: string
  }[]
  lotterRegisterTitle?: string
}) {
  if (!lotteryWays?.length) {
    return null
  }
  return (
    <Container>
      <StyledContainer>
        <StyledInnerContainer>
          <NewText
            mobileFontSize={24}
            desktopFontSize={32}
            component={"h2"}
            desktopLineHeight={"36px"}
            mobileLineHeight={"100%"}
            color="black"
          >
            {lotterRegisterTitle}
          </NewText>
          <BoxGrid>
            {lotteryWays.map((el, i) => (
              <BoxContainer key={i}>
                <HeaderFlex>
                  <Desktop>
                    {el.icon && <Image width={48} height={48} alt={el.title || ""} src={el.icon} />}
                  </Desktop>
                  <Mobile>
                    {el.icon && <Image width={40} height={40} alt={el.title || ""} src={el.icon} />}
                  </Mobile>
                  <NewText
                    desktopFontSize={20}
                    mobileFontSize={18}
                    desktopFontWeight={600}
                    color="black"
                    component={"h3"}
                  >
                    {el.title}
                  </NewText>
                </HeaderFlex>
                <StyledNewText
                  desktopFontSize={18}
                  mobileFontSize={16}
                  desktopLineHeight={1.45}
                  color="black"
                  component={"div"}
                  dangerouslySetInnerHTML={{
                    __html:
                      el.description?.replace(
                        "<a",
                        `<a style="color:black; underline" target="_blank"`,
                      ) || "",
                  }}
                />
                {el.linkURL && <StyledLink href={el.linkURL}>{el.linkName}</StyledLink>}
              </BoxContainer>
            ))}
          </BoxGrid>
        </StyledInnerContainer>
      </StyledContainer>
    </Container>
  )
}
