"use client"

import { BlockRenderer, Container, CustomBreadcrumbs, TopBanner } from "ui"
import { getBreadCrumbs } from "utils"
import { findProperty } from "utils/findProperty"
import { InvestoreRelationsMessages } from "../InvestorsRelation/InvestorsRelation"
import BondsMain from "./BondsMain"

type Props = {
  data: any
  bondsData: any
  messages: InvestoreRelationsMessages
  locale: string
}

function Bonds({ data, bondsData, messages, locale }: Props) {
  const { selfObject, parents } = getBreadCrumbs(data, locale)
  const components = {
    topBanner: TopBanner,
    pgeMainTitle: BondsMain,
  }
  const blocks = findProperty(data.page, "blocks")?.blocks || []
  const hasBanner = blocks?.some(
    (block: any) => block.blockType === "topBanner" || block.blockType === "HeaderWithHeroAndText",
  )
  return (
    <>
      <Container>
        <CustomBreadcrumbs current={selfObject} parents={parents} />
      </Container>
      {blocks?.map((block: any, index: number) => {
        let additionalProps = {}
        if (block.blockType === "contentText") {
          additionalProps = {
            hasBanner: hasBanner,
          }
        } else if (block.blockType === "pgeMainTitle") {
          additionalProps = {
            bondsData: bondsData,
            messages,
            locale,
          }
        }
        return (
          <BlockRenderer
            key={index}
            blockType={block.blockType}
            blockData={block}
            components={components}
            props={additionalProps}
          />
        )
      })}
    </>
  )
}

export default Bonds
