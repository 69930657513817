"use client"

import { BlockRenderer, Container, CustomBreadcrumbs, Hero } from "ui"
import { extractProperty, getBreadCrumbs } from "utils"
import BottomTextAndImage from "./BottomTextAndImage"
import Content from "./Content"
import HebrewButton from "./HebrewButton"
import Jobs from "./Jobs/Jobs"
import JobsQuoteGallery from "./JobsQuoteGallery"
import TopContent from "./TopContent"

export type CareerLobbyMessages = {
  findJob: string
  company: string
  all: string
  locaiton: string
  jobType: string
  jobCategory: string
  requiredFields: string
  jobsBoard: string
  viewResults: string
  jobNo: string
  jobDescr: string
  viewJob: string
  more: string
}

type Props = {
  data: any
  filters?: any
  jobs?: any
  locale: string
  messages: CareerLobbyMessages
  allData: any
  companyDivisionId: number[]
}

const components = {
  hEROBanner: Hero,
  topContent: TopContent,
  jobsQuoteGallery: JobsQuoteGallery,
  bottomTextAndImage: BottomTextAndImage,
  content: Content,
  hebrewButton: HebrewButton,
}

function Careers({
  data,
  filters,
  jobs,
  locale,
  messages,
  allData,
  companyDivisionId = [],
}: Props) {
  const dataToRender = data?.page
  const id = extractProperty(dataToRender, "idForAnchor")
  const { selfObject, parents } = getBreadCrumbs(data, locale)
  const hasBanner = data?.page?.properties?.some(
    (block: any) => block.blockType === "topBanner" || block.blockType === "HeaderWithHeroAndText",
  )
  return (
    <>
      <Container>
        <CustomBreadcrumbs current={selfObject} parents={parents} />
      </Container>
      {data?.page?.properties?.map((block: any, index: number) => {
        let additionalProps = {}
        if (block.blockType === "contentText") {
          additionalProps = {
            hasBanner: hasBanner,
          }
        } else if (block.propertyName === "content") {
          additionalProps = {
            id: id || "jobs",
          }
        } else if (block.propertyName === "hEROBanner") {
          additionalProps = {
            noContent: true,
          }
        }
        return (
          <BlockRenderer
            key={index}
            blockType={block.propertyName}
            blockData={block}
            locale={locale}
            components={components}
            props={additionalProps}
          />
        )
      })}
      {filters && jobs && locale === "he" && (
        <Jobs
          filters={filters}
          jobs={jobs}
          locale={locale}
          messages={messages}
          allData={allData}
          companyDivisionId={companyDivisionId}
        />
      )}
    </>
  )
}

export default Careers
